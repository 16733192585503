import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import { Fragment } from 'react';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';
import { useNavigate, useLocation } from "react-router-dom";
import { Empty, Table, Input } from 'antd';
import ContractorDetailsSlider from './ContractorDetailsSlider';
import { useMediaQuery } from 'react-responsive';
import { SM_SCREEN, XL_SCREEN } from '../../services/SpaService';

export default function ContractorManager(props) {
    const navigate = useNavigate();
    const location = useLocation();

    const isDesktop = useMediaQuery({ minWidth: XL_SCREEN });
    const isMobile = useMediaQuery({ maxWidth: SM_SCREEN });

    const [state, setState] = useState({
        contractors: [],
        selectedContractor: null,
        dataLoaded: false,
        formFields: {},
        noContractors: true,
        searchValue: '',
        selectedContractorStatus: 'All',
        selectedIdx: null,
        showContractorDetailsSlider: false,
        contractorID: null,
        contractorName: '',
        contractorsLoaded: false,
    });

    const [selectedRowKeys, setSelectedRowKeys] = useState(['']);
    const [contractors, setContractors] = useState([]);
    const [filteredContractors, setfilteredContractors] = useState([]);
    const [selectedContractorId, setSelectedContractorId] = useState([]);
    const [currentPage, setCurrentPage] = useState([]);
    const [currentPageSize, setCurrentPageSize] = useState([]);

    useEffect(() => {
        setCurrentPage(1);
        setCurrentPageSize(10);

        GetContractors();
    }, []);

    useEffect(() => {
        GetContractors();
    }, [state.selectedContractorStatus]);

    useEffect(() => {
        var myPageSize = 10;
        var myPageIndex = 1;

        if (state.contractorsLoaded === true) {
            if (location.state !== null) {
            //if (location.state !== null && currentPage !== null && currentPage !== undefined) {
                //undefined since we are hitting the customers list after coming from a top nav search
                if (currentPageSize === undefined) {
                    myPageSize = 1;
                }
                else {
                    myPageSize = currentPageSize;
                }

                //now go calculate the page to jump to by finding the selectedCustNum and the page size
                for (let i = 0; i < location.state.contractors.length; i++) {
                    if (location.state.contractors[i].contractors_ID === location.state.selectedContractorId) {
                        myPageIndex = Math.floor(i / myPageSize) + 1

                        break;
                    }
                }

                //now set the selected Contractor
                let selRows = [location.state.selectedContractorId];
                setSelectedRowKeys([...selRows]);
            }

            updatePageState(myPageIndex, myPageSize);

            setState({
                ...state,
                showContractorDetailsSlider: false,
            });
        }
    }, [state.contractorsLoaded]);

    function GetContractors() {
        setState({
            ...state,
            showContractorDetailsSlider: false,
            contractorsLoaded: false
        });

        Axios.get(`/api/GetContractors`, {
            params: {
                contractorStatus: state.selectedContractorStatus
            }
        }).then(response => {
            let contractorsList = response.data;

            setContractors(contractorsList);
            setfilteredContractors(contractorsList);

            setState({
                ...state,
                contractorsLoaded: true,
                showContractorDetailsSlider: false
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function JumpToContractor(e, record) {
        setSelectedContractorId(record.contractors_ID);

        navigate("/contractor-dashboard", {
            state: {
                selectedContractor: record, selectedContractorId: record.contractors_ID, selectedContractorName: record.name,
                currentPage: currentPage, currentPageSize: currentPageSize, 
                filteredContractors: filteredContractors, contractors: contractors
            }
        });
    }

    function handleContractorStatusChange(e) {
        setState({
            ...state,
            showContractorDetailsSlider: false,
            selectedContractorStatus: e.target.value
        });
    }

    function handleDelete(e, record) {
        confirm({
            title: "You are about to permanently delete this Contractor",
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"]
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {

                //this uses the ignore field in the AccountsModel to pass in the selected filterType to be used when returning the new list of Accounts
                record.contractorStatus = state.selectedContractorStatus;

                Axios.post(`/api/DeleteContractor`, record
                ).then(response => {
                    if (response.data) {
                        toast.success("Contractor has been deleted");

                        setContractors(response.data);
                        setfilteredContractors(response.data);

                        setState({
                            ...state,
                            contractors: response.data,
                            dataLoaded: true
                        });
                    }
                }).catch(error => {
                    console.log(error);
                });

                return 0;
            } else {
                return 0;
            }
        });
    }

    async function updateContractors(newContractorsList, selectedContractorId)
    {
        var myPageSize = 10;
        var myPageIndex = 1;

        setSelectedContractorId(selectedContractorId);
        setContractors(newContractorsList);
        setfilteredContractors(newContractorsList);

        for (let i = 0; i < newContractorsList.length; i++) {
            if (newContractorsList[i].contractors_ID === selectedContractorId) {
                myPageIndex = Math.floor(i / myPageSize) + 1
                break;
            }
        }

        updatePageState(myPageIndex, myPageSize);

        //now set the selected Contractor
        let selRows = [selectedContractorId];
        setSelectedRowKeys([...selRows]);

        setState({
            ...state,
            showContractorDetailsSlider: false
        });
    }

    function handleAddCustomer()
    {
        setState({
            ...state,
            selectedContractorId: null,
            showContractorDetailsSlider: true,
            selectedIdx: null,
            loading: false,
            selectedContractorStatus: 'All'
        });
    }

    const columns = [
        {
            dataIndex: 'contractors_ID',
            title: 'contractors_ID',
            key: 'contractors_ID',
            hidden: true
        }, {
            dataIndex: 'name',
            title: 'Name',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name)
        }, {
            dataIndex: 'city',
            title: 'City',
            key: 'city',
            sorter: (a, b) => a.city.localeCompare(b.city), 
            responsive: ['lg']
        }, {
            dataIndex: 'state',
            title: 'State',
            key: 'state',
            sorter: (a, b) => a.state.localeCompare(b.state),
            responsive: ['lg']
        }, {
            dataIndex: 'status',
            title: 'Status',
            key: 'status',
            sorter: (a, b) => a.city.localeCompare(b.city),
            responsive: ['lg']
        }, {
            title: 'Manage Contractor',
            key: 'action',
            width: 120,
            render: (record) => (
                <span className="hover" onClick={(e) => JumpToContractor(e, record)}><i id="contactDetails" className="fas fa-edit fa-1x ml-3 text-center hover"></i></span>
            ),
            align: 'center'
        }, {
            title: 'Delete Contact',
            key: 'action2',
            width: 120,
            render: (record) => (
                <i id="deleteContractor" className="far fa-trash-alt fa-1x ml-3 text-center hover text-danger" onClick={(e) => handleDelete(e, record)}></i>
            ),
            align: 'center'
        }
    ].filter(item => !item.hidden);

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: 0, // Set the width to 0
        renderCell: () => "", // Render nothing inside
    };

    function searchContractors(value) {
        let searchMatches = [];
        var currContractor;
        var valueCleared = false;
        for (let i = 0; i < contractors.length; i++) {
            currContractor = contractors[i];

            if (value !== '') {
                if (currContractor.name.toLowerCase().includes(value.toLowerCase()) || currContractor.city.toLowerCase().includes(value.toLowerCase()) || currContractor.state.toLowerCase().includes(value.toLowerCase())) {
                    searchMatches = [...searchMatches, contractors[i]];
                }
            }
            else {
                valueCleared = true;
            }
        }

        setfilteredContractors(valueCleared ? [...contractors] : [...searchMatches]);
    }

    function searchValueChanged(e) {
        setState({
            ...state,
            searchValue: e.target.value
        });

        searchContractors(e.target.value);
    }

    function updatePageState(page, pageSize) {
        setCurrentPage(page);
        setCurrentPageSize(pageSize);

        setState({
            ...state,
        });
    }

    return (
        <div>
            <div className="row">
                <div className="col-lg-12" >
                    <div className="panel-hdr">
                        <h2>
                            MANAGE CONTRACTORS
                        </h2>

                        <button type="button" className="btn btn-submit margin-0-10" onClick={handleAddCustomer}>
                            Add Contractor
                        </button>
                    </div>
                    <Fragment>
                        <br />
                        <div className="row">
                            <div>
                                <label className="form-label">Contractor Status:</label>&nbsp;&nbsp;
                                <input type="radio" name="contractorStatus" onChange={handleContractorStatusChange} value="All" className="mt-1" checked={state.selectedContractorStatus === "All"} />&nbsp;<label className="form-label">All</label>&nbsp;&nbsp;&nbsp;
                                <input type="radio" name="contractorStatus" onChange={handleContractorStatusChange} value="A" className="mt-1" checked={state.selectedContractorStatus === "A"} />&nbsp;<label className="form-label">Active</label>&nbsp;&nbsp;&nbsp;
                                <input type="radio" name="contractorStatus" onChange={handleContractorStatusChange} value="I" className="mt-1" checked={state.selectedContractorStatus === "I"} />&nbsp;<label className="form-label">Inactive</label>&nbsp;&nbsp;&nbsp;
                                <input type="radio" name="contractorStatus" onChange={handleContractorStatusChange} value="N" className="mt-1" checked={state.selectedContractorStatus === "N"} />&nbsp;<label className="form-label">New</label>
                            </div>
                        </div>
                        <div className="frame-wrap">
                            {state.loading ?
                                <span className="frame-heading"><ReactLoading className='tableLoading' type={"spokes"} color={'#F30079'} height={30} width={30} /> Loading ...</span>
                                :
                                <div className="row">
                                    <Input.Search
                                        style={{ paddingTop: "20px", margin: "0 0 10px 0", width: "50%" }}
                                        placeholder="Search Contractors..."
                                        enterButton
                                        onSearch={searchContractors}
                                        allowClear
                                        value={state.searchValue}
                                        onChange={searchValueChanged}
                                    />
                                    {filteredContractors.length === 0 ?
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                                            <span>
                                                No Contractors Found Matching Your Selection Criteria
                                            </span>
                                        } />
                                        :
                                        <Fragment>
                                            <div>
                                                <Table className="custom-ant-selection"
                                                    rowKey={item => item.contractors_ID}
                                                    rowSelection={rowSelection}
                                                    hideSelectionColumn={true}
                                                    size="small"
                                                    bordered
                                                    dataSource={filteredContractors}
                                                    columns={columns}
                                                    pagination={{
                                                        defaultPageSize: 10,
                                                        showSizeChanger: true,
                                                        pageSizeOptions: ['10', '25', '50', '100'],
                                                        showTotal: (total, range) => (
                                                            <span className="color-dark-blue" style={{ left: 0, position: "absolute", fontSize: "large", fontWeight: "bold" }}>
                                                                Showing {range[0]}-{range[1]} of {total}
                                                            </span>
                                                        ),
                                                        onChange: (page, pageSize) => {
                                                            updatePageState(page, pageSize);
                                                        },
                                                        current: currentPage,
                                                        pageSize: currentPageSize
                                                    }}
                                                    expandable={isMobile && {
                                                        expandedRowRender: (record) => (
                                                            <ExpandedRow record={record} />
                                                        ),
                                                        rowExpandable: (record) => record.name !== 'Not Expandable',
                                                    }}
                                                    onRow={(record, index) => {
                                                        return {
                                                            onClick: () => {
                                                                let selRows = [record.contractors_ID];
                                                                setSelectedRowKeys([...selRows]);
                                                            },
                                                            onDoubleClick: (e) => {
                                                                JumpToContractor(e, record);
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </Fragment>
                                    }
                                </div>
                            }
                        </div>
                        {state.showContractorDetailsSlider &&
                            <ContractorDetailsSlider selectedContractorId={state.selectedContractorId} showPane={state.showContractorDetailsSlider} contractors={state.contractors} updateContractors={updateContractors} hidePane={() => setState({ ...state, showContractorDetailsSlider: false })} />
                        }
                    </Fragment>
                </div>
            </div>
        </div>
    );
}

const ExpandedRow = ({ record }) =>
(
    <div className="row">
        <div className="form-group col-lg-4 col-md-6 col-12">
            <label className="form-label">City:&nbsp;</label>
            <span>{record.city}</span>
        </div>
        <div className="form-group col-lg-4 col-md-6 col-12">
            <label className="form-label">State:&nbsp;</label>
            <span>{record.state}</span>
        </div>
        <div className="form-group col-lg-4 col-md-6 col-12">
            <label className="form-label">Status:&nbsp;</label>
            <span>{record.status}</span>
        </div>
    </div>
);