import React, { useState, useEffect } from 'react';
import Axios from '../../config/axios';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { toast, confirm } from '@rickylandino/react-messages';
import { Fragment } from 'react';
import Card from 'react-bootstrap/Card';
import statesList from '../Helpers/StatesList';
import NumberFormat from 'react-number-format';
import { message } from 'antd';
import { useForm, Controller } from "react-hook-form";

export default function ContactDetailsSlider(props) {
    const [state, setState] = useState({
        showPane: props.showPane,
        contacts: [],
        selectedContactId: '',
        selectedIdx: '',
        formFields: {},
        btnSubmitClicked: false,
        buttonLabel: '',
        states: statesList,
        selectedContractorId: ''
    });

    const { register, getValues, setValue, control, watch } = useForm();

    useEffect(() => {
        //setState({
        //    ...state,
        //    states: statesList,
        //    showPane: true
        //});
    }, []);

    useEffect(() => {
        if (props.showPane) {
            if (props.selectedContactId !== null && props.selectedContactId !== "") {
                Axios.get(`/api/GetContactById`, {
                    params: {
                        contactID: props.selectedContactId
                    }
                }).then(response => {
                    setValue('formFields', response.data);

                    setState({
                        ...state,
                        formFields: response.data,
                        showPane: props.showPane,
                        contacts: props.contacts,
                        selectedContactId: props.selectedContactId,
                        selectedContractorId: props.selectedContractorId,
                        selectedIdx: props.selectedIdx,
                        buttonLabel: 'Update Contact Info'
                    });
                }).catch(error => {
                    console.log(error);
                });
            }
            else {
                setState({
                    contacts: props.contacts,
                    selectedContractorId: props.selectedContractorId,
                    formFields: {},
                    showPane: props.showPane,
                    buttonLabel: 'Add New Contact'
                }, () => console.log('hello'));
            }
        }
    }, [props]);

    function hidePane() {
        setState({
            ...state,
            showPane: false
        });

        setTimeout(() => {
            props.hidePane();
        }, 1000);
    }

    function handleSubmit() {
        let formFields = getValues('formFields');

        if (formFields.contactName === undefined || formFields.contactName === '') {
            toast.error("Mandatory fields missing!");
            return;
        } else {
            if (state.selectedContactId) {
                Axios.post(`/api/UpdateContact`, formFields
                ).then(response => {
                    toast.success("Contact Information Successfully Updated");

                    //now update the parent list
                    //the next line basically creates kvp with an object named memberModel 
                    let contacts = state.contacts;
                    const selectedIdx = contacts.findIndex(a => a.contacts_ID === props.selectedContactId);
                    contacts[selectedIdx] = formFields;
                    props.updateTableDisplay(contacts, selectedIdx);

                    hidePane();
                }).catch(error => {
                    toast.error("Problem Encountered Updating Contact");

                    console.log(error);
                });
            }
            else {
                formFields.contractors_ID = state.selectedContractorId;

                Axios.post(`/api/AddNewContact`, formFields
                ).then(response => {
                    if (response.data) {
                        toast.success("New Contact Successfully Added");

                        //now update the parent list
                        let contacts = response.data.contacts;
                        const idxToDirectTo = contacts.findIndex(a => a.contacts_ID === response.data.newID);
                        props.updateTableDisplay(contacts, idxToDirectTo);
                    }

                    hidePane();
                }).catch(error => {
                    toast.error("Problem Encountered Adding New Contact");

                    console.log(error);
                });
            }
        }
    }

    function handleSendWelcomeEmail() {
        let formFields = getValues('formFields');

        if (formFields.contactEmail === '' || formFields.contactEmail === undefined || formFields.contactName === '' || formFields.contactName === undefined) {
            toast.error("A Contact Name and Email must be provided before sending a welcome email.");
            return;
        }

        const key = "sendwelcomeemail";
        message.loading({
            content: 'Sending Welcome Email...',
            key,
            duration: 0
        });

        let postdata = {
            EmailRecipients: formFields.contactEmail,
            EmailNotificationType: 'WelcomeEmail',
            EmailRecipientName: formFields.contactName
        }

        Axios.post(`/api/SendWelcomeEmail`, postdata
        ).then(response => {
            message.destroy(key);

            if (response.data === true) {
                toast.success("Welcome email successfully sent to " + formFields.contactName);

                //this.hidePane();
            } else {
                toast.error("There was a problem sending welcome email");
            }
        }).catch(error => {
            console.log(error);
        });
    }

    return (
        <SlidingPane
            className='some-custom-class w-65'
            overlayClassName='showCard'
            isOpen={state.showPane}
            title='Contact Info'
            onRequestClose={hidePane}
        >
            <div className="slide-pane-body scrollableDiv">
                <div>
                    {!state.loading &&
                        <Fragment>
                            <div className="panel-hdr">
                                {state.selectedContactId && (<h2>{getValues('formFields.contactName')}</h2>)}
                                {!state.selectedContactId && (<h2>New Contact</h2>)}
                            </div>
                            <Card className="local777Card">
                                <Card.Header><span>Contact Information (*Indicates required field)</span></Card.Header>
                                <Card.Body>
                                    <form>
                                        <div className="row">
                                            <div className="form-group col-lg-12">
                                                <label className="form-label">Contact Name</label>
                                                <input type="text" name="contactName" {...register('formFields.contactName')} className="form-control-custom" />
                                            </div>

                                            <div className="form-group col-lg-12">
                                                <label className="form-label">Title</label>
                                                <input type="text" name="contactTitle" {...register('formFields.contactTitle')} className="form-control-custom" />
                                            </div>

                                            <div className="form-group col-lg-12">
                                                <label className="form-label">Email</label>
                                                <input type="text" name="contactEmail" {...register('formFields.contactEmail')} className="form-control-custom" />
                                            </div>

                                            <div className="form-group col-lg-4">
                                                <label className="form-label">Phone</label>
                                                <input type="text" name="contactPhone" {...register('formFields.contactPhone')} className="form-control-custom" />
                                            </div>
                                            <div className="form-group col-lg-8">

                                            </div>
                                            <div className="form-group col-lg-12">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id="mainContact"
                                                    name="mainContact"
                                                    defaultChecked={getValues('formFields.mainContact')}
                                                    {...register('formFields.mainContact')}
                                                />
                                                <label className="custom-control-label form-label">&nbsp;Main Contact</label>
                                            </div>
                                            <div className="form-group col-lg-12">
                                                Note: Main Contacts will have the ability to request a new contact for this contractor
                                            </div>

                                            {/*<div className="form-group col-lg-4">*/}
                                            {/*    <label className="form-label">Password</label>*/}
                                            {/*    <input type="text" name="contactPassword" value={state.formFields.contactPassword || ''} onChange={handleInputChange} className="form-control-custom" />*/}
                                            {/*</div>*/}
                                            {/*<div className="form-group col-lg-8">*/}

                                            {/*</div>*/}
                                        </div>
                                   
                                    </form>
                                </Card.Body>
                            </Card>
                        </Fragment>
                    }
                </div>
            </div>

            <div className="modal-footer">
                <div className="form-group col-12 padding-25-10">
                    <button className="btn btn-submit margin-left-15 float-left" onClick={handleSubmit}>Save Changes</button>&nbsp;&nbsp;&nbsp;
                    <button className="btn btn-submit margin-left-15 float-left" onClick={handleSendWelcomeEmail}>Send Welcome Email</button>&nbsp;&nbsp;&nbsp;
                    <button className="btn btn-outline-primary margin-left-15 float-right" onClick={hidePane}>Close</button>
                </div>
            </div>
        </SlidingPane>
    );
}