import React, { useState, useEffect } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { useForm, Controller } from "react-hook-form";
import Axios from '../../config/axios';
import { dateWithNoTimezone } from '../Helpers/DateFormat';
import { Fragment } from 'react';
import Moment from 'moment';
import { toast, confirm } from '@rickylandino/react-messages';
import { Spin, Alert, Empty, Table, Checkbox } from 'antd';

export default function CheckUtilitiesResetHoursSlider(props) {
    const { register, getValues, setValue, control, watch } = useForm();

    const [state, setState] = useState({
        showPane: props.showPane,
        hoursPaidOnCheck: {},
        selectedEntries: []
    });

    const [selectedRowKeys, setSelectedRowKeys] = useState(['']);
    const [currentPage, setCurrentPage] = useState([1]);
    const [currentPageSize, setCurrentPageSize] = useState([10]);

    useEffect(() => {
        var alive = true;

        if (alive && props.checkNo !== null) {
            Axios.get(`/api/GetAllHoursPaidOnCheck`, {
                params: {
                    checkNo: props.checkNo
                }
            }).then(response => {
                let hoursPaidOnCheck = response.data;

                console.log(hoursPaidOnCheck);

                setState({
                    ...state,
                    hoursPaidOnCheck,
                    dataLoaded: true,
                    showPane: true,
                    selectedEntries: []
                });
            }).catch(error => {
                console.log(error);
            });
        }
        return () => {
            alive = false;
        };

    }, [props]);

    function hidePane() {
        setState({
            ...state,
            showPane: false,
        });
    }

    const columns = [
        {
            title: 'marketRecoveryHours_ID', dataIndex: 'marketRecoveryHours_ID', key: 'marketRecoveryHours_ID', hidden: true
        }, {
            dataIndex: 'selected',
            title: 'Select',
            key: 'selected',
            width: 50,
            render: (cell, row, idx) => {
                return (
                    <>
                        <Checkbox id="chkSelect" onChange={(event) => handleSelectItem(event, cell, row, idx)} checked={row.selected}  />
                    </>
                )
            },
            align: 'center'
        }, {
            dataIndex: 'weekEnding',
            title: 'Week Ending',
            key: 'weekEnding',
            width: 140,
            render: (weekEnding) => {
                return (
                    <div>
                        {weekEnding === null ? '' : Moment(dateWithNoTimezone(weekEnding)).format("L")}
                    </div>
                );
            },
            sorter: (a, b) => a.weekEnding.localeCompare(b.weekEnding)
        }, {
            dataIndex: 'projectName',
            title: 'Job',
            key: 'projectName',
            sorter: (a, b) => a.projectName.localeCompare(b.projectName)
        }, {
            dataIndex: 'contractorName',
            title: 'Contractor',
            key: 'contractorName',
            sorter: (a, b) => a.contractorName.localeCompare(b.contractorName)
        }, {
            dataIndex: 'employee',
            title: 'Employee',
            key: 'employee',
            sorter: (a, b) => a.contractorName.localeCompare(b.contractorName)
        }, {
            dataIndex: 'hoursRequested',
            title: 'Hours Requested',
            key: 'hoursRequested',
            sorter: (a, b) => a.contractorName.localeCompare(b.contractorName)
        }, {
            dataIndex: 'actualHoursPaid',
            title: 'Hours Paid',
            key: 'actualHoursPaid',
            sorter: (a, b) => a.contractorName.localeCompare(b.contractorName)
        }
    ].filter(item => !item.hidden);

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: 0, // Set the width to 0
        renderCell: () => "", // Render nothing inside
    };

    function handleSelectItem(event, cell, row, idx) {
        const target = event.target;
        const value = target.checked;

        let myHoursPaidOnCheck = [...state.hoursPaidOnCheck];
        let myselectedEntries = state.selectedEntries;

        const idxInMasterList = myHoursPaidOnCheck.indexOf(row);

        if (value) {
            myHoursPaidOnCheck[idx].selected = true;
            myselectedEntries.push(row);

            myHoursPaidOnCheck[idxInMasterList].selected = true;
        }
        else {
            const index = myselectedEntries.indexOf(row);
            if (index > -1) {
                myHoursPaidOnCheck[index].selected = false;
                myselectedEntries.splice(index, 1);
            }

            myHoursPaidOnCheck[idxInMasterList].selected = false;
        }

        setState({
            ...state,
            hoursPaidOnCheck: myHoursPaidOnCheck,
            selectedEntries: myselectedEntries
        });
    }

    function onSelectAll() {
        //this checks all the boxes on all the pages
        let myHoursPaidOnCheck = [...state.hoursPaidOnCheck];
        let mySelectedEntries = [];

        for (var i = 0; i < myHoursPaidOnCheck.length; i++) {
            myHoursPaidOnCheck[i].selected = true;
            mySelectedEntries.push(myHoursPaidOnCheck[i]);
        }

        setState({
            ...state,
            hoursPaidOnCheck: myHoursPaidOnCheck,
            selectedEntries: mySelectedEntries
        });
    }

    function onDeselectAll() {
        //this unchecks all the boxes on all the pages
        let myHoursPaidOnCheck = state.hoursPaidOnCheck;

        for (var i = 0; i < myHoursPaidOnCheck.length; i++) {
            myHoursPaidOnCheck[i].selected = false;
        }

        setState({
            ...state,
            hoursPaidOnCheck: myHoursPaidOnCheck,
            selectedEntries: []
        });
    }

    function handleResetHours() {
        if (state.selectedEntries.length === 0) {
            toast.error("No Items Are Selected To Reset");
            return;
        }

        confirm({
            title: "All selected hours will be reset",
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"],
            theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {
                let mySelectedEntries = state.selectedEntries;

                let postdata = mySelectedEntries
           
                //set selected request to 'Lost'
                Axios.post(`/api/ResetHoursOnCheck`, postdata
                ).then(response => {
                    if (response.data > 0) {
                        toast.success("Selected hours on check # " + props.checkNo + " reset successfully");
                    }
                    else {
                        toast.error("Error resetting hours check # " + props.checkNo + " contact your system administrator");
                    }

                    setState({
                        ...state,
                        showPane: false
                    });

                }).catch(error => {
                    toast.error("There was a problem resetting hours");
                    console.log(error);
                });

                return 0;
            } else {
                return 0;
            }
        });
    }

    function updatePageState(page, pageSize) {
        var index;

        //this requecks the boxes on all the pages
        let myHoursPaidOnCheck = state.hoursPaidOnCheck;
        let mySelectedEntries = state.selectedEntries;

        for (var i = 0; i < myHoursPaidOnCheck.length; i++) {
            index = mySelectedEntries.indexOf(myHoursPaidOnCheck[i]);
            if (index > -1) {
                myHoursPaidOnCheck[i].selected = true;
            }
        }

        setCurrentPage(page);
        setCurrentPageSize(pageSize);

        setState({
            ...state,
            hoursPaidOnCheck: myHoursPaidOnCheck
        });
    }

    return (
        <SlidingPane
            className='some-custom-class w-65'
            overlayClassName='showCard'
            isOpen={state.showPane}
            title='Reset Hours For Check'
            onRequestClose={hidePane}
        >
            <div className="slide-pane-body scrollableDiv">
                <Spin spinning={!state.dataLoaded}>
                    <div style={!state.dataLoaded ? {} : { display: 'none' }}>
                        <Alert
                            message="Loading Market Recovery Hours On Check"
                            description="Please stand by while we retrieve all houyrs paid on the selected check"
                            type="info"
                        />
                    </div>
                    {state.dataLoaded &&
                        <Fragment>
                            {state.hoursPaidOnCheck.length === 0 ?
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                                    <span>
                                        No hours found for the selected check
                                    </span>
                                } />
                                :
                                <div>
                                    <Table className="custom-ant-selection"
                                        rowKey={(record) => record.marketRecoveryHours_ID}
                                        rowSelection={rowSelection}
                                        hideSelectionColumn={true}
                                        bordered
                                        size="small"
                                        dataSource={state.hoursPaidOnCheck}
                                        style={{ whiteSpace: 'pre' }}
                                        columns={columns}
                                        pagination={{
                                            defaultPageSize: 10,
                                            showSizeChanger: true,
                                            pageSizeOptions: ['10', '25', '50', '100'],
                                            showTotal: (total, range) => (
                                                <span className="color-dark-blue" style={{ left: 0, position: "absolute", fontSize: "large", fontWeight: "bold" }}>
                                                    Showing {range[0]}-{range[1]} of {total}
                                                </span>
                                            ),
                                            onChange: (page, pageSize) => {
                                                updatePageState(page, pageSize);
                                            },
                                            current: currentPage,
                                            pageSize: currentPageSize
                                        }}
                                        onRow={(record, index) => {
                                            return {
                                                onClick: () => {
                                                    let selRows = [record.marketRecoveryHours_ID];
                                                    setSelectedRowKeys([...selRows]);
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            }
                        </Fragment>
                    }
                </Spin>
            </div>

            <div className="modal-footer">
                <div className="form-group col-12 pt-4">
                    <span><button className="btn btn-submit btn-sm" onClick={onSelectAll}>Select All</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span><button className="btn btn-submit btn-sm" onClick={onDeselectAll}>De-Select All</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <button className="btn btn-submit margin-left-15 float-left btn-sm" onClick={handleResetHours}>Reset Hours</button>&nbsp;&nbsp;
                    {/*{state.selectedEntries.length} Items Selected*/}
                    <button className="btn btn-outline-primary margin-left-15 float-right" onClick={hidePane}>Close</button>
                </div>
            </div>
        </SlidingPane>
    );
}