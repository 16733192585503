import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import './login.css';
import logo from '../../img/logo.png';
import Globals from '../../config/globals';
import Axios from '../../config/axios';
import cookie from 'react-cookies';
import ReactLoading from 'react-loading';
import { toast, confirm } from '@rickylandino/react-messages';
import { Modal } from 'react-bootstrap';

export default function ForgotPassword(props) {
    const [loading, setLoading] = useState(false);

    const [state, setState] = useState({
        formFields: {
            userid: ''
        }
    });

    //handleInputChange = (event) => {
    //    const target = event.target;
    //    const value = target.type === 'checkbox' ? target.checked : target.value;
    //    const name = target.name;

    //    this.setState({ formFields: { ...this.state.formFields, [name]: value } });
    //}

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setState({
            ...state,
            formFields: { ...state.formFields, [name]: value }
        });
    }

    function handleSubmit(e) {
        e.preventDefault();

        if (state.formFields.userid === null || state.formFields.userid === "") {
            document.getElementById("userid").focus();
            toast.error("You must provide a valid email address");
            return;
        }

        setState({
            ...state,
            loading: true
        });

        let postdata = {
            userid: state.formFields.userid
        };

        Axios.post(`/api/SendPasswordResetEmail`, postdata
        ).then(response => {
            setState({
                ...state,
                loading: false
            });

            if (response.data === 'OK') {
                toast.success("An email has been sent to " + state.formFields.userid + " with instructions and a link to reset their password");
            }
            else {

            }
            props.goHome();

        }).catch(error => {
            setState({
                ...state,
                loading: false
            });

            let message = "Something went wrong, please try again later or call Andrea Berkmoes at (203) 317-4750 or <a href=\"mailto: aberkmoes@local777.com\">aberkmoes@local777.com</a>.";
            toast.error(<span style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: message }}></span>, { alignment: 'top-center', duration: 5 })
        });
    }

    return (
        <form>
            <h3>Reset Your Password</h3>
            <p>
                Enter the email address associated with your account and instructions <br />
                on how to update your password will be sent to this email address.
            </p>
            <div className="form-group">
                <label className="form-label" htmlFor="userid">Enter the email address associated with your account</label>
                <input type="text" id="userid" className="form-control-custom" name="userid" value={state.formFields.userid} onChange={handleInputChange} />
            </div>
            {state.loading &&
                <ReactLoading className='tableLoading float-left' type={"spokes"} color={'#275282'} height={30} width={30} />
            }
            <button className="btn btn-default float-right" onClick={handleSubmit}>Submit</button>
        </form>
    );
}
