import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import Axios from '../../config/axios';
import CVContractorTab from './CVContractorTab';
import CVContactsTab from './CVContactsTab';
import CVRequestsTab from './CVRequestsTab';
import { Tabs } from 'antd';

export default function CVDashboard(props) {
    const navigate = useNavigate();
    const location = useLocation();

    const [state, setState] = useState({
        selectedContractorId: '',
        contractor: {},
        formFields: {}
    });

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const { TabPane } = Tabs;

    useEffect(() => {
        setState({
            ...state,
            selectedContractorId: location.state?.selectedContractorId
        });
    }, []);

    useEffect(() => {
        var alive = true;

        if (alive && location.state?.selectedContractorId !== null) {
            Axios.get(`/api/GetContractorById`, {
                params: {
                    contractorID: location.state?.selectedContractorId
                }
            }).then(response => {
                let contractor = response.data;

                setState({
                    ...state,
                    contractor: contractor,
                    dataLoaded: true,
                    selectedContractorId: location.state?.selectedContractorId
                });
            }).catch(error => {
                console.log(error);
            });
        }
        return () => {
            alive = false;
        };
    }, [props]);

    function backToSearchResults() {
        navigate("/contractor-manager", { replace: true, state: { pageNumber: location.state.pageNumber, selectedContractorId: state.selectedContractorId } });
    }

    console.log(userInfo.mainContact);

    return (
        <div>
            <div className="row padding-top-15 ml-3 mr-3">
                <div className="col">
                    <h3 className="">{state.contractor.name}</h3>
                </div>

                <div className="card-container">
                    <Tabs defaultActiveKey='tab-activate-a-device' tabPosition='top' type='card'>
                        <TabPane tab='Company Information' key='tab-activate-a-device'>
                            <CVContractorTab selectedContractorId={state.selectedContractorId} contractor={state.contractor} />
                        </TabPane>

                        {userInfo.mainContact === true &&
                            <TabPane tab='Company Contacts' key='tab-manage-exhibitor-leads'>
                                <CVContactsTab selectedContractorId={state.selectedContractorId} contractorName={state.contractor.name} />
                            </TabPane>
                        }

                        <TabPane tab='Market Recovery Requests' key='tab-reporting-tools'>
                            <CVRequestsTab selectedContractorId={state.selectedContractorId} />
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        </div>
    );
}