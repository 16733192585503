import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import statesList from '../Helpers/StatesList';
import NumberFormat from 'react-number-format';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';

export default function CVContractorTab(props) {
    const [state, setState] = useState({
        showPane: props.showPane,
        contractor: {},
        selectedContractorId: '',
        formFields: {},
        btnSubmitClicked: false,
        states: statesList
    });

    useEffect(() => {
        if (props.contractor !== null) {
            setState({
                ...state,
                formFields: props.contractor
            });
        }
    }, [props]);

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setState({
            ...state,
            formFields: { ...state.formFields, [name]: value }
        });
    }

    function handleMCACMemberChange(e) {
        setState({
            ...state,
            formFields: { ...state.formFields, mcacMember: e.target.value }
        });
    }

    function handleStatusChange(e) {
        setState({
            ...state,
            formFields: { ...state.formFields, status: e.target.value }
        });
    }

    function handleSubmit() {
        let formFields = state.formFields;

        //this uses the ignore field in the AccountsModel to pass in the selected filterType to be used when returning the new list of Accounts
        formFields.typeFilter = props.selectedAccountType;

        if (!state.formFields?.name) {
            toast.error("Mandatory fields missing!");
            return;
        } else {
            Axios.post(`/api/UpdateContractor`, formFields
            ).then(response => {
                toast.success("Contractor Information Successfully Updated");
            }).catch(error => {
                toast.error("Problem Encountered Updating Contractor");

                console.log(error);
            });
        }
    }

    return (
        <div>
            <Card className="local777Card">
                <Card.Header><span>Company Information</span></Card.Header>
                <Card.Body>
                    <form>
                        <div className="row">
                            <div className="form-group col-lg-6">
                                <label className="form-label">Contractor Name</label>
                                <input type="text" name="name" value={state.formFields.name || ''} onChange={handleInputChange} className="form-control-custom" readOnly />
                            </div>
                            <div className="form-group col-lg-6"></div>

                            <div className="form-group col-lg-6">
                                <label className="form-label">Street 1</label>
                                <input type="text" name="street1" value={state.formFields.street1 || ''} onChange={handleInputChange} className="form-control-custom" readOnly />
                            </div>
                            <div className="form-group col-lg-6">
                                <label className="form-label">Street 2</label>
                                <input type="text" name="street2" value={state.formFields.street2 || ''} onChange={handleInputChange} className="form-control-custom" readOnly />
                            </div>

                            <div className="form-group col-lg-4">
                                <label className="form-label">City</label>
                                <input type="text" name="city" value={state.formFields.city || ''} onChange={handleInputChange} className="form-control-custom" readOnly />
                            </div>
                            <div className="form-group col-lg-4">
                                <label className="form-label">State</label>
                                <select className="form-control-custom" value={state.formFields.state} name="state" onChange={handleInputChange} readOnly>
                                    <option></option>
                                    {state.states.map((state, idx) => <option key={idx} value={state.abbreviation}>{state.name}</option>)}
                                </select>
                            </div>
                            <div className="form-group col-lg-4">
                                <label className="form-label">Zip</label>
                                <input type="text" name="zip" value={state.formFields.zip || ''} onChange={handleInputChange} className="form-control-custom" readOnly />
                            </div>

                            <div className="form-group col-lg-4">
                                <label className="form-label">Phone</label>
                                <NumberFormat className="form-control-custom" name="phone" value={state.formFields.phone || ''} onChange={handleInputChange} format="###-###-####" mask="_" readOnly />
                            </div>
                            <div className="form-group col-lg-4">
                                <label className="form-label">Fax</label>
                                <NumberFormat className="form-control-custom" name="fax" value={state.formFields.fax || ''} onChange={handleInputChange} format="###-###-####" mask="_" readOnly />
                            </div>
                            <div className="form-group col-lg-4">
                                <label className="form-label">Web Site</label>
                                <input type="text" name="website" value={state.formFields.website || ''} onChange={handleInputChange} className="form-control-custom" readOnly />
                            </div>

                            <div className="form-group col-lg-3">
                                <label className="form-label">MCAC Member:</label><br />
                                <input type="radio" onChange={handleMCACMemberChange} name="mcacMember" value="Y" className="mt-1" checked={state.formFields.mcacMember === "Y"} disabled />&nbsp;<label className="form-label">Yes</label>&nbsp;&nbsp;&nbsp;
                                <input type="radio" onChange={handleMCACMemberChange} name="mcacMember" value="N" className="mt-1" checked={state.formFields.mcacMember === "N"} disabled />&nbsp;<label className="form-label">No</label>&nbsp;&nbsp;&nbsp;
                            </div>
                            <div className="form-group col-lg-3"></div>
                            <div className="form-group col-lg-6"></div>
                        </div>
                    </form>
                    {/*<div className="modal-footer">*/}
                    {/*    <div className="form-group col-12 padding-25-10">*/}
                    {/*        <button className="btn btn-submit margin-left-15 float-left" onClick={handleSubmit}>Save Changes</button>&nbsp;&nbsp;&nbsp;*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </Card.Body>
            </Card>
        </div>
    );
}