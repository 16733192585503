import React, { useState, useEffect } from 'react'
import { Route, Navigate, withRouter, useParams, useLocation, Routes, useNavigate } from 'react-router-dom';
import { hot } from 'react-hot-loader/root'
import { test } from './services/TestService';
import { activeRoute } from './services/SpaService';
import Modal from 'react-modal';
import { Modal as AntModal } from 'antd';
import { toast } from '@rickylandino/react-messages';
import { Layout } from './components/Layout';
import Login from './components/Auth/Login';
import CVDashboard from './components/ContractorsView/CVDashboard';
import ContractorManager from './components/ContractorManager/ContractorManager';
import ContractorDashboard from './components/ContractorManager/ContractorDashboard';
import RequestManager from './components/Requests/RequestManager';
import MyRequests from './components/Requests/MyRequests';
import MROldRequest from './components/Requests/MROldRequest';
import RequestArchiver from './components/Requests/RequestArchiver';
import JobManager from './components/Jobs/JobManager';
import ProcessPayments from './components/Payments/ProcessPayments';
import CheckUtilities from './components/Utilities/CheckUtilities';
import DataCleanup from './components/Utilities/DataCleanup';
import UserManager from './components/Users/UserManager';
import RoleTemplateManager from './components/Admin/RoleTemplateManager';
import MenuManager from './components/Admin/MenuManager';
import ResetPassword from './components/Auth/ResetPassword';
import { insertNewVersion } from './services/CachingService';
import { getVersion } from './services/ApiService';
import ProcessPayments2 from './components/Payments/ProcessPayments2';

function App(props) {
    const location = useLocation();

    const [state, setState] = useState({
        refreshCache: false
    });

    useEffect(() => {
        activeRoute.next(location.pathname);

        Modal.setAppElement('#root');

        getVersionInformation();

        AntModal.destroyAll();
    }, []);

    async function getVersionInformation() {
        let basePath = window.location.href;
        var baseUrl = basePath.replace(/\/+$/, '') + '/' + 'meta.json';

        const response = await fetch(baseUrl, {
            cache: 'no-store'
        });

        const meta = await response.json();

        getVersion().then(data => {
            if (`${meta.version}` !== data.cacheVersion) {
                toast.loading("Update in progress");
                setState({
                    refreshCache: true
                });

                insertNewVersion(meta.version).then(data => {
                    props.emptyCache();
                });
            } else if (window.localStorage.APP_VERSION !== data.cacheVersion && !props.isLatestVersion) {
                toast.loading("Update in progress");
                setState({
                    refreshCache: true
                });

                props.emptyCache();
            }
        });
    }

    return (
        <div className="nav-function-top">
            {
                location.pathname === '/' || location.pathname.includes('reset-password') ? (
                    <Routes>
                        <Route path="/" element={<Login />} />
                        <Route path="/reset-password/:id/:date" element={<ResetPassword />} />
                        {/*<Route path="/reset-password" element={<ResetPassword />} />*/}
                    </Routes>
                ) : 
                    <Layout>
                        <Routes>
                            <Route path="/dashboard" element={<CVDashboard />} />
                            <Route path="/contractor-manager" element={<ContractorManager />} />
                            <Route path="/contractor-dashboard" element={<ContractorDashboard />} />
                            <Route path="/manage-contractor-requests" element={<RequestManager />} />
                            <Route path="/mr-old-request" element={<MROldRequest />} />
                            <Route path="/archive-requests" element={<RequestArchiver />} />
                            <Route path="/job-manager" element={<JobManager />} />
                            <Route path="/process-payments" element={<ProcessPayments2 />} />
                            <Route path="/utilities" element={<CheckUtilities />} />
                            <Route path="/data-cleanup" element={<DataCleanup />} />
                            <Route path="/user-manager" element={<UserManager />} />
                            <Route path="/role-template-manager" element={<RoleTemplateManager />} />
                            <Route path="/menu-manager" element={<MenuManager />} />
                        </Routes>
                    </Layout>
            }
            {state.refreshCache && <div className="refresh-overlay" />}
        </div>
    );
}

export default process.env.NODE_ENV === "development" ? hot(App) : App;