import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import { Fragment } from 'react';
import Globals from '../../config/globals';
import Axios from '../../config/axios';
import { Spin, Alert, Empty, Table } from 'antd';
import { toast, confirm } from '@rickylandino/react-messages';
import { useNavigate, useLocation } from "react-router-dom";
import UserDetailsSlider from './UserDetailsSlider';

export default function UserManager() {
    const [state, setState] = useState({
        users: [],
        selectedUser: null,
        dataLoaded: false,
        noUsers: true,
        selectedIdx: null,
        showUserDetailsSlider: false,
        selectedUserId: null,
    });

    const [selectedRowKeys, setSelectedRowKeys] = useState(['']);
    const [currentPage, setCurrentPage] = useState([1]);
    const [currentPageSize, setCurrentPageSize] = useState([10]);

    useEffect(() => {
        GetUsers();
    }, []);

    function GetUsers() {
        Axios.get(`/api/GetAllUsers`, {
            params: {
                
            }
        }).then(response => {
            let usersList = response.data;

            setState({
                ...state,
                users: usersList,
                //selectedContractor: response.data.length > 0 && response.data[0],
                dataLoaded: true,
                results: usersList.slice(0, 10),
                showUserDetailsSlider: false
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function LoadUserDetails(e, record) {
        Axios.get(`/api/GetUserById`, {
            params: {
                userid: record.users_ID
            }
        }).then(response => {
            setState({
                ...state,
                selectedUser: response.data,
                selectedUserId: record.users_ID,
                showUserDetailsSlider: true
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function handleDeleteUser(e, record) {
        confirm({
            title: "You are about to permanently delete this User",
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"]
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {
                Axios.post(`/api/DeleteUser`, record
                ).then(response => {
                    if (response.data) {
                        toast.success("User has been deleted");

                        setState({
                            ...state,
                            users: response.data,
                            dataLoaded: true
                        });
                    }
                }).catch(error => {
                    console.log(error);
                });

                return 0;
            } else {
                return 0;
            }
        });
    }

    function handleAddUser()
    {
        setState({
            ...state,
            selectedUserId: null,
            showUserDetailsSlider: true,
            selectedIdx: null,
            loading: false
        });
    }

    async function updateTableDisplay(users, idxToDirectTo = null) {
        var myPageSize = 10;
        var myPageIndex = 1;

        await setState({ ...state, dataLoaded: false });

        for (let i = 0; i < users.length; i++) {
            if (users[i].users_ID === users[idxToDirectTo].users_ID) {
                myPageIndex = Math.floor(i / myPageSize) + 1
                break;
            }
        }

        updatePageState(myPageIndex, myPageSize);

        //now set the selected User
        let selRows = [users[idxToDirectTo].users_ID];
        setSelectedRowKeys([...selRows]);

        setState({
            ...state,
            users,
            selectedUserId: users[idxToDirectTo],
            dataLoaded: true,
            //showUserDetailsSlider: false
        });
    }

    const columns = [
        {
            dataIndex: 'users_ID',
            title: 'users_ID',
            key: 'users_ID',
            hidden: true
        }, {
            dataIndex: 'lastName',
            title: 'Last Name',
            key: 'lastName',
            sorter: (a, b) => a.lastName.localeCompare(b.lastName)
        }, {
            dataIndex: 'firstName',
            title: 'First Name',
            key: 'firstName',
            sorter: (a, b) => a.firstName.localeCompare(b.firstName)
        }, {
            dataIndex: 'title',
            title: 'Title',
            key: 'title',
            sorter: (a, b) => a.title.localeCompare(b.title)
        }, {
            title: 'Manage User',
            key: 'action',
            width: 120,
            render: (record) => (
                <span className="hover" onClick={(e) => LoadUserDetails(e, record)}><i id="manageUser" className="fas fa-edit fa-1x ml-3 text-center hover"></i></span>
            ),
            align: 'center'
        }, {
            title: 'Delete User',
            key: 'action2',
            width: 120,
            render: (record) => (
                <i id="deleteUser" className="far fa-trash-alt fa-1x ml-3 text-center hover text-danger" onClick={(e) => handleDeleteUser(e, record)}></i>
            ),
            align: 'center'
        }
    ].filter(item => !item.hidden);

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: 0, // Set the width to 0
        renderCell: () => "", // Render nothing inside
    };

    function updatePageState(page, pageSize) {
        setCurrentPage(page);
        setCurrentPageSize(pageSize);

        setState({
            ...state,
        });
    }

    return (
        <div>
            <div className="row">
                <div className="col-lg-12" >
                    <div className="panel-hdr">
                        <h2>
                            MANAGE USERS
                        </h2>

                        <button type="button" className="btn btn-submit margin-0-10" onClick={handleAddUser}>
                            Add User
                        </button>
                    </div>
                    <Fragment>
                        <br />
                        <div>
                            <Spin spinning={!state.dataLoaded}>
                                <div style={!state.dataLoaded ? {} : { display: 'none' }}>
                                    <Alert
                                        message="Loading Market Recovery Users"
                                        description="Please stand by while we retrieve users list"
                                        type="info"
                                    />
                                </div>
                                {state.dataLoaded &&
                                    <Fragment>
                                        {state.users.length === 0 ?
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                                                <span>
                                                    No Market Recovery Users Found
                                                </span>
                                            } />
                                            :
                                            <div>
                                                <Table className="custom-ant-selection"
                                                    rowKey={(record) => record.users_ID}
                                                    rowSelection={rowSelection}
                                                    hideSelectionColumn={true}
                                                    bordered
                                                    size="small"
                                                    dataSource={state.users}
                                                    style={{ whiteSpace: 'pre' }}
                                                    columns={columns}
                                                    pagination={{
                                                        defaultPageSize: 10,
                                                        showSizeChanger: true,
                                                        pageSizeOptions: ['10', '25', '50', '100'],
                                                        showTotal: (total, range) => (
                                                            <span className="color-dark-blue" style={{ left: 0, position: "absolute", fontSize: "large", fontWeight: "bold" }}>
                                                                Showing {range[0]}-{range[1]} of {total}
                                                            </span>
                                                        ),
                                                        onChange: (page, pageSize) => {
                                                            updatePageState(page, pageSize);
                                                        },
                                                        current: currentPage,
                                                        pageSize: currentPageSize
                                                    }}
                                                    onRow={(record, index) => {
                                                        return {
                                                            onClick: () => {
                                                                let selRows = [record.users_ID];
                                                                setSelectedRowKeys([...selRows]);
                                                            },
                                                            onDoubleClick: (e) => {
                                                                LoadUserDetails(e, record);
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>
                                        }
                                    </Fragment>
                                }
                            </Spin>
                        </div>
                        {state.showUserDetailsSlider &&
                            <UserDetailsSlider users={state.users} selectedUserId={state.selectedUserId} showPane={state.showUserDetailsSlider} updateTableDisplay={updateTableDisplay} hidePane={() => setState({ ...state, showUserDetailsSlider: false })} />
                        }
                    </Fragment>
                </div>
            </div>
        </div>
    );
}