import React, { useState, useEffect } from 'react';
import { Fragment } from 'react';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';
import { Select, Modal, Spin, Alert, Empty, Collapse, Table } from 'antd';
import { useForm, Controller } from "react-hook-form";
import Moment from 'moment';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import NumberFormat from 'react-number-format';
import greencheck from '../../img/greencheck.png';
import redx from '../../img/redx.png';
import mailflagred from '../../img/MailFlagRed.png';

import { useMediaQuery } from 'react-responsive';
import { SM_SCREEN, XL_SCREEN } from '../../services/SpaService';
import { dateWithNoTimezone } from '../Helpers/DateFormat';

export default function ProcessPayments2() {
    const { Panel } = Collapse;

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });

    var tempList = [];

    const [state, setState] = useState({
        pendingPayments: [],
        dataLoaded: false,
        showRequestDetailsSlider: false,
        requestEditMode: 'Add',
        formFields: {},
        selectedUniqueRowID: '',
        hideActionObjects: false
    });

    const isDesktop = useMediaQuery({ minWidth: XL_SCREEN });
    const isMobile = useMediaQuery({ maxWidth: SM_SCREEN });

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const [modalInfo, setModalInfo] = useState({
        showmodal: false,
        content: {}
    });

    const [ppModalInfo, setPPModalInfo] = useState({
        showmodal: false,
        content: {}
    });

    const [contractorsListData, setContractorsListData] = useState([]);

    const [jobsListData, setJobsListData] = useState([]);

    const [agentsListData, setAgentsListData] = useState([]);

    const [selectedRecord, setSelectedRecord] = useState({});

    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(10);

    const { register, getValues, setValue, control } = useForm();

    useEffect(() => {

        setValue('formFields', {});
        if (userInfo.userType === 'Agent' || userInfo.userType === 'SuperAgent') {
            setValue('formFields.requestAgent', userInfo.userId);
        }
        else {
            setValue('formFields.requestAgent', 'All');
        }
        setValue('formFields.fromDate', null);
        setValue('formFields.toDate', null);
        setValue('formFields.contractor', null)
        setValue('formFields.job', null)
        setValue('formFields.archived', false)
        setValue('formFields.paymentStatus', 'Outstanding');
        setValue('formFields.hideActionObjects', false);

        InitializePaymentProcessor();
    }, []);

    function InitializePaymentProcessor() {
        Axios.get(`/api/InitializePaymentProcessor`, {
            params: {

            }
        }).then(response => {
            let contractorsList = response.data.contractors;
            let jobsList = response.data.jobs;
            let agentsList = response.data.agents;

            setContractorsListData(contractorsList);
            setJobsListData(jobsList);
            setAgentsListData(agentsList);

            setState({
                ...state,
                dataLoaded: false
            });

            GetFilteredPendingPayments();

        }).catch(error => {
            console.log(error);
        });
    }

    const getWeekEndingDateStr = (date) => {

        if (date === null) { return ""; }

        let year = date.getFullYear().toString();
        let month = (date.getMonth() + 1).toString();
        let day = date.getDate().toString();

        if (month.length == 1) { month = '0' + month; }
        if (day.length == 1) { day = '0' + day; }

        return year + month + day;
    }

    function GetFilteredPendingPayments() {
        Axios.get(`/api/GetFilteredPendingPayments`, {
            params: {
                fromDate: getWeekEndingDateStr(getValues().formFields.fromDate),
                toDate: getWeekEndingDateStr(getValues().formFields.toDate),
                contractorID: getValues().formFields.contractor,
                requestAgent: getValues().formFields.requestAgent,
                jobID: getValues().formFields.job,
                archived: getValues().formFields.archived,
                paymentStatus: getValues().formFields.paymentStatus
            }
        }).then(response => {
            let paymentsToProcessList = response.data;

            if (getValues().formFields.paymentStatus === 'Paid') {
                setValue('formFields.hideActionObjects', true);
            }
            else {
                setValue('formFields.hideActionObjects', false);
            }

            setState({
                ...state,
                pendingPayments: paymentsToProcessList,
                dataLoaded: true
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function handleContractorStatusChange(e) {
        setState({
            ...state,
            selectedContractorStatus: e.target.value
        });
    }

    function handleDelete(contractor) {
        confirm({
            title: "You are about to permanently delete this Contractor",
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"]
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {

                //this uses the ignore field in the AccountsModel to pass in the selected filterType to be used when returning the new list of Accounts
                contractor.contractorStatus = state.selectedContractorStatus;

                Axios.post(`/api/DeleteContractor`, contractor
                ).then(response => {
                    if (response.data) {
                        toast.success("Contractor has been deleted");

                        setState({
                            ...state,
                            contractors: response.data,
                            dataLoaded: true
                        });
                    }
                }).catch(error => {
                    console.log(error);
                });

                return 0;
            } else {
                return 0;
            }
        });
    }

    function initNotesModalFields(row, rowIndex) {
        var indexAcrossAllPages = ((currentPage - 1) * currentPageSize) + rowIndex;

        setValue('modalFields.indexAcrossAllPages', indexAcrossAllPages);
        setValue('modalFields.requestID', row.paymentHeader.requestID);
        setValue('modalFields.contractors_ID', row.paymentHeader.contractors_ID);
        setValue('modalFields.weekEnding', row.paymentHeader.weekEnding);
        setValue('modalFields.paymentNote', row.paymentHeader.paymentNote);
        setValue('modalFields.noteToContractor', row.paymentHeader.noteToContractor);
        if (row.paymentHeader.exceptions === 1) {
            setValue('modalFields.notesExceptions', 'Exceptions');
        }
        else {
            setValue('modalFields.notesExceptions', 'NoExceptions');
        }
        if (row.paymentHeader.paymentNote !== null && row.paymentHeader.paymentNote !== "") {
            setValue('modalFields.noteEditMode', 'Edit');
        }
        else {
            setValue('modalFields.noteEditMode', 'Add');
        }
        setState({
            ...state,
            selectedUniqueRowID: row.paymentHeader.uniqueRowID,
        });
    }

    function openPaymentNotesModal() {
        setValue('crEmailContractor', true);

        setModalInfo({
            ...modalInfo,
            showmodal: true
        });
    }

    function awardedFormatter(cell, row) {
        if (row.paymentHeader.dollarsPerHour >= 10) {
            return (
                <span><img alt="awardedflag" className="ms-2" src={mailflagred} style={{ height: '15px' }} /></span>
            );
        }
        else {
            return (
                <span></span>
            );
        }
    }

    function notesFormatter(cell, row, idx) {
        function openNotes() {
            var indexAcrossAllPages = ((currentPage - 1) * currentPageSize) + idx;

            initNotesModalFields(row, idx);
            openPaymentNotesModal(row.paymentHeader.requestID);
        }

        if (row.paymentHeader.paymentNote != null) {
            if (row.paymentHeader.exceptions === 0) {
                return (
                    <span onClick={openNotes}><i id="editNotes" className="fas fa-edit fa-1x ml-3 text-center hover"></i><img alt="Local777" className="ms-2" src={greencheck} style={{ height: '15px' }} /></span>
                );
            }
            else {
                return (
                    <span onClick={openNotes}><i id="editNotes" className="fas fa-edit fa-1x ml-3 text-center hover"></i><img alt="Local777" className="ms-2" src={redx} style={{ height: '15px' }} /></span>
                );
            }
        }
        else {
            return (
                <span onClick={openNotes}><i id="editNotes" className="fas fa-edit fa-1x ml-3 text-center hover"></i></span>
            );
        }
    }

    function selectFormatter(cell, row, idx) {

        function handleChkChange(e) {
            if (e.target.checked) {
                var indexAcrossAllPages = ((currentPage - 1) * currentPageSize) + idx;

                var newList = [...state.pendingPayments];

                var itemToUpdate = { ...newList[indexAcrossAllPages] };

                itemToUpdate.paymentHeader.includeOnCheck = !itemToUpdate.paymentHeader.includeOnCheck;
                newList[indexAcrossAllPages] = itemToUpdate;

                setState({
                    ...state,
                    pendingPayments: newList
                });
            }
        }

        if (getValues().formFields.paymentStatus === 'Outstanding') {
            return (
                <input type="checkbox" id="chkInclude" name="chkInclude" onChange={handleChkChange} defaultChecked={row.paymentHeader.includeOnCheck} />
            );
        }
        else {
            return (
                <div style={{ color: "green" }}>Paid</div>
            );
        }
    }

    function detailsFormatter(cell, row) {
        return (
            <div>{row.paymentHeader.contractorName}<br />{row.paymentHeader.jobName}</div>
        );
    }

    function weekEndingFormatter(cell, row) {
        let myWeekEnding = row.paymentHeader.weekEnding.substr(4, 2) + '/' + row.paymentHeader.weekEnding.substr(6, 2) + '/' + row.paymentHeader.weekEnding.substr(0, 4);
        return (
            <div>{myWeekEnding}</div>
        );
    }
    function dateFormatter(cell, row) {
        var mydate = Moment(dateWithNoTimezone(row.dateSubmitted)).format("L");
        return (
            <div>{mydate}</div>
        );
    }

    function employeeTypeFormatter(cell, row) {
        if (row.employeeType === 'J') {
            return (
                <div>Journeyman</div>
            );
        }
        else {
            return (
                <div>Apprentice</div>
            );
        }
    }
    function hrsTotalFormatter(cell, row) {
        var myTotal = (row.mon + row.tue + row.wed + row.thu + row.fri + row.sat + row.sun).toString();
        return (
            <div><label>{myTotal}</label></div>
        );
    }

    function getUpdateFromChildRow(rowID, newList) {

        let newParent = [...state.pendingPayments];

        var idxToUpdate = newParent.findIndex(item => item.paymentHeader.uniqueRowID === rowID);
        if (idxToUpdate >= 0) {
            newParent[idxToUpdate].hoursList = newList;
        }

        tempList = newParent;
    }

    function onChangeHrsToPay(e, parentIndex, row, childIndex) {

        const target = e.target;
        const value = target.value;

        if (target.value === '') {
            target.value = 0;
        }

        var pendingPayments = [...state.pendingPayments];
        var singlePendingPayment = { ...pendingPayments[parentIndex] };

        var newList = [ ...singlePendingPayment.hoursList ];

        var itemToUpdate = { ...newList[childIndex] };

        if (itemToUpdate) {
            itemToUpdate.hrsTotal = parseFloat(value);
            newList[childIndex] = itemToUpdate;
        }

        getUpdateFromChildRow(singlePendingPayment.paymentHeader.uniqueRowID, newList);
    }

    function onPaymentStatusChange(value) {
        setValue('formFields.paymentStatus', value)

        setState({
            ...state,
            showRequestDetailsSlider: false
        });
    }

    function onJobChange(value) {
        setValue('formFields.job', value)

        setState({
            ...state,
            showRequestDetailsSlider: false
        });
    }

    function handleSearch() {
        setState({
            ...state,
            dataLoaded: false
        });

        GetFilteredPendingPayments();
    }

    function awardedFormatter(cell, row) {
        if (row.paymentHeader.dollarsPerHour >= 10) {
            return (
                <span><img alt="awardedflag" className="ms-2" src={mailflagred} style={{ height: '15px' }} /></span>
            );
        }
        else {
            return (
                <span></span>
            );
        }
    }


    const expandedRowRender = (record, parentIndex, indent, expanded) => {
        const subColumns = [
            {
                title: 'Employee',
                dataIndex: 'employee',
                key: 'employee',
            },
            {
                title: 'SS #',
                dataIndex: 'ssNumber',
                key: 'ssNumber',
            },
            {
                title: 'Hrs To Pay',
                key: 'hrsTotal',
                dataIndex: 'hrsTotal',
                render: hrsTotalFormatter
            },
            {
                title: 'Hrs To Pay',
                key: 'hrsToPay',
                render: (cell, row, idx) => <input type="number" min="0" step="0.1" max={row.hrsTotal} className="form-control-custom" defaultValue={row.hrsTotal} onChange={(e) => onChangeHrsToPay(e, parentIndex, row, idx)} disabled={getValues().formFields?.hideActionObjects}  />
            },
            {
                title: 'Type',
                dataIndex: 'employeeType',
                key: 'employeeType',
                render: employeeTypeFormatter
            },
            {
                title: 'Date Submitted',
                dataIndex: 'dateSubmitted',
                key: 'dateSubmitted',
                render: dateFormatter
            },
            {
                title: 'Hourly Rate',
                dataIndex: 'dollarsPerHour',
                key: 'dollarsPerHour',
                render: (cell) => cell && formatter.format(cell)
            }
        ];

        const data = record.hoursList;
        
        return (
            <>
                <div className="row">
                    <div className="form-group col-lg-4 col-md-6 col-12">
                        <label className="form-label">Agent: </label>
                        <span>{record.paymentHeader?.agentName}</span>
                    </div>
                    <div className="form-group col-lg-4 col-md-6 col-12">
                        <label className="form-label">Week Ending: </label>
                        <span>{record.paymentHeader?.weekEnding}</span>
                    </div>
                    <div className="form-group col-lg-4 col-md-6 col-12">
                        <label className="form-label">Hours: </label>
                        <span>{record.paymentHeader?.hrsTotal}</span>
                    </div>
                    <div className="form-group col-lg-4 col-md-6 col-12">
                        <label className="form-label">Hrs Granted: </label>
                        <span>{record.paymentHeader?.grantedHours}</span>
                    </div>
                    <div className="form-group col-lg-4 col-md-6 col-12">
                        <label className="form-label">Already Paid: </label>
                        <span>{record.paymentHeader?.actualHoursPaid}</span>
                    </div>
                    <div className="form-group col-lg-4 col-md-6 col-12">
                        <label className="form-label">Hrs Remaining: </label>
                        <span>{record.paymentHeader?.hrsRemaining}</span>
                    </div>
                </div>

                <hr style={{ border: "1px solid" }} />

                <Table columns={subColumns} dataSource={data} rowKey={record => record.marketRecoveryHours_ID} pagination={false} />
            </>
        );
    };

    const columns2 = [
        {
            title: '',
            key: 'awarded',
            render: awardedFormatter
        }, {
            title: 'Include On Check',
            key: 'includeOnCheck',
            render: selectFormatter
        }, {
            dataIndex: ['paymentHeader', 'contractorName'],
            title: 'Contractor / Job',
            key: 'contractorName',
            render: detailsFormatter
        }, {
            dataIndex: ['paymentHeader', 'agentName'],
            title: 'Agent',
            key: 'agentName',
            responsive: ['xxl']
        }, {
            dataIndex: ['paymentHeader', 'weekEnding'],
            title: 'Week Ending',
            key: 'weekEnding',
            render: weekEndingFormatter,
            responsive: ['xxl']
        }, {
            dataIndex: ['paymentHeader', 'hrsTotal'],
            title: 'Hours',
            key: 'hrsTotal',
            responsive: ['xxl']
        }, {
            dataIndex: ['paymentHeader', 'grantedHours'],
            title: 'Hrs Granted',
            key: 'grantedHours',
            responsive: ['xxl']
        }, {
            dataIndex: ['paymentHeader', 'actualHoursPaid'],
            title: 'Already Paid',
            key: 'actualHoursPaid',
            responsive: ['xxl']
        }, {
            dataIndex: ['paymentHeader', 'hrsRemaining'],
            title: 'Hrs Remaining',
            key: 'hrsRemaining',
            responsive: ['xxl']
        }, {
            title: 'Notes',
            key: 'notes',
            render: notesFormatter
        }
    ].filter(item => !item.hidden);

    function onContractorChange(value) {
        setValue('formFields.contractor', value)

        setState({
            ...state,
            showRequestDetailsSlider: false
        });
    }

    function onAgentChange(value) {
        setValue('formFields.requestAgent', value)

        setState({
            ...state,
            showRequestDetailsSlider: false
        });
    }

    function handleReset() {
        setState({
            ...state,
            dataLoaded: false
        });

        if (userInfo.userType === 'Agent' || userInfo.userType === 'SuperAgent') {
            setValue('formFields.requestAgent', userInfo.userId);
        }
        else {
            setValue('formFields.requestAgent', 'All');
        }

        setValue('formFields.fromDate', null);
        setValue('formFields.toDate', null);
        setValue('formFields.contractor', null)
        setValue('formFields.job', null)
        setValue('formFields.archived', false)
        setValue('formFields.paymentStatus', 'Outstanding');

        GetFilteredPendingPayments();
    }

    function onProcessPaymentNotesChange() {
        if (getValues().modalFields.paymentNote === '' || getValues().modalFields.paymentNote === null) {
            toast.error("An internal note is required");
            return;
        }

        var myExceptions;

        if (getValues().modalFields.notesExceptions === "Exceptions") {
            myExceptions = 1;
        }
        else {
            myExceptions = 0
        }

        let postdata = {
            RequestID: getValues().modalFields.requestID,
            Contractors_ID: getValues().modalFields.contractors_ID,
            WeekEnding: getValues().modalFields.weekEnding,
            PaymentNote: getValues().modalFields.paymentNote,
            Exceptions: myExceptions,
            NoteToContractor: getValues().modalFields.noteToContractor
        }

        if (getValues().modalFields.noteEditMode === 'Edit') {
            Axios.post(`/api/UpdatePaymentNote`, postdata
            ).then(response => {
                toast.success("Payment Note Successfully Updated");
                updateTableDisplay();
            }).catch(error => {
                toast.error("Problem Encountered Updating Payment Note");

                console.log(error);
            });
        }
        else {
            Axios.post(`/api/AddNewPaymentNote`, postdata
            ).then(response => {
                toast.success("Payment Note Successfully Added");
                updateTableDisplay();

            }).catch(error => {
                toast.error("Problem Encountered Adding Payment Note");

                console.log(error);
            });
        }
    }

    async function updateTableDisplay() {
        await setState({ ...state, dataLoaded: false });

        var indexAcrossAllPages = getValues().modalFields.indexAcrossAllPages;

        var newList = [...state.pendingPayments];
        var itemToUpdate = newList[indexAcrossAllPages];

        itemToUpdate.paymentHeader.paymentNote = getValues().modalFields.paymentNote;
        itemToUpdate.paymentHeader.noteToContractor = getValues().modalFields.noteToContractor;
        if (getValues().modalFields.notesExceptions === 'Exceptions') {
            itemToUpdate.paymentHeader.exceptions = 1;
        }
        else {
            itemToUpdate.paymentHeader.exceptions = 0;
        }

        newList[indexAcrossAllPages] = itemToUpdate;

        setState({
            ...state,
            pendingPayments: newList,
            selectedUniqueRowID: itemToUpdate.paymentHeader.uniqueRowID,
            dataLoaded: true
        });

        closeModal();
    }

    function closeModal() {
        setModalInfo({
            ...modalInfo,
            showmodal: false
        });
    }

    function calculateSummaryFields() {
        var itemsSelected = 0;
        var totHours = 0.0;
        var totAmount = 0.0;

        state.pendingPayments.forEach((payment, idx) => {
            if (payment.paymentHeader.includeOnCheck === true) {
                itemsSelected += 1;

                payment.hoursList.forEach((hrs, idx) => {
                    if (payment.paymentHeader.includeOnCheck === true) {
                        totHours += hrs.hrsTotal;
                    }

                    if (hrs.employeeType === 'J') {
                        totAmount += (hrs.hrsTotal * payment.paymentHeader.dollarsPerHour)
                    }
                    else {
                        totAmount += (hrs.hrsTotal * payment.paymentHeader.dollarsPerHourApprentice)
                    }
                });
            }
        });

        setValue('ppModalFields.itemsSelected', itemsSelected);
        setValue('ppModalFields.totalHours', totHours);

        if (totAmount) {
            setValue('ppModalFields.totalAmount', formatter.format(totAmount));
        }
    }

    function showSummary() {
        if (getValues().formFields.paymentStatus === 'Paid') {
            toast.info("This feature not applicable when viewing payment status 'Paid'");
            return;
        }

        calculateSummaryFields();

        setValue('ppModalFields.displayMode', 'showSummary');

        setPPModalInfo({
            ...modalInfo,
            showmodal: true
        });
    }

    function closePPModal() {
        setPPModalInfo({
            ...modalInfo,
            showmodal: false
        });
    }

    function assignSelectedHoursToCheck() {
        if (getValues().formFields.paymentStatus === 'Paid') {
            toast.info("This feature not applicable when viewing payment status 'Paid'");
            return;
        }

        calculateSummaryFields();

        setValue('ppModalFields.displayMode', 'assignHoursToCheck');
        setValue('ppModalFields.checkNo', '');

        setPPModalInfo({
            ...modalInfo,
            showmodal: true
        });
    }

    function onAssignHoursToCheck() {
        if (getValues().ppModalFields.checkNo === '') {
            toast.error("A check number must be provided");

            return;
        }

        if (getValues().ppModalFields?.itemsSelected === 0) {
            toast.error("No hours were selected for processing");

            return;
        }

        setState({
            ...state,
            dataLoaded: false
        });

        let myPayments = state.pendingPayments;

        myPayments.forEach((payment, idx) => {
            payment.assignToCheck = getValues().ppModalFields.checkNo;
        });

        let postdata = myPayments;

        Axios.post(`/api/AssignHoursToCheck`, postdata
        ).then(response => {
            GetFilteredPendingPayments();
            closePPModal();
            toast.success("Hours Assigned Successfully");

        }).catch(error => {
            setState({
                ...state,
                dataLoaded: true
            });

            toast.error("Problem Encountered Adding Payment Note");
        });
    }

    function updatePageState(page, pageSize) {
        setCurrentPage(page);
        setCurrentPageSize(pageSize);
    }

    function onExpand(expanded, record) {
        setSelectedRecord(record);
    }

    return (
        <div>
            <div className="row">
                <div className="col-lg-12" >
                    <div className="panel-hdr">
                        <h2>
                            PROCESS PAYMENTS
                        </h2>
                    </div>
                    <Fragment>
                        <br />
                        <Collapse defaultActiveKey={!isMobile ? ['1'] : []}>
                            <Panel header="Search Filters" key="1">
                                <div className="row">
                                    <div className="form-group col-lg-3">
                                        <label className="form-label">Agent</label><br />
                                        <Select
                                            {...register("formFields.requestAgent")}
                                            size="large" placeholder="All"
                                            className="form-control-custom"
                                            showSearch
                                            allowClear={false}
                                            optionFilterProp="children"
                                            onChange={onAgentChange}
                                            filterOption={(input, option) =>
                                                option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 || false
                                            }
                                            value={getValues().formFields?.requestAgent}
                                        >
                                            <option value="All">All</option>
                                            {agentsListData?.length > 0 && agentsListData?.map((agent, idx) => <option index={idx} key={agent.users_ID} value={agent.users_ID}>{agent.firstName} {agent.lastName}</option>)}
                                        </Select>
                                    </div>
                                    <div className="form-group col-lg-3">
                                        <label className="form-label">From Date</label>
                                        <Controller
                                            name="formFields.fromDate"
                                            control={control}
                                            setValue={setValue}
                                            defaultValue={getValues().formFields?.fromDate ? Moment(getValues().formFields.fromDate).toDate() : null}
                                            render={() =>
                                                <DatePicker
                                                    selected={getValues().formFields?.fromDate ? Moment(getValues().formFields.fromDate).toDate() : null}
                                                    onChange={date => setValue('formFields.fromDate', date)}
                                                    className="form-control-custom"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    customInput={
                                                        <NumberFormat format="##/##/####" mask="_" />
                                                    } />
                                            }
                                        />
                                    </div>
                                    <div className="form-group col-lg-3">
                                        <label className="form-label">To Date</label>
                                        <Controller
                                            name="formFields.toDate"
                                            control={control}
                                            setValue={setValue}
                                            defaultValue={getValues().formFields?.toDate ? Moment(getValues().formFields.toDate).toDate() : null}
                                            render={() =>
                                                <DatePicker
                                                    selected={getValues().formFields?.toDate ? Moment(getValues().formFields.toDate).toDate() : null}
                                                    onChange={date => setValue('formFields.toDate', date)}
                                                    className="form-control-custom"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    customInput={
                                                        <NumberFormat format="##/##/####" mask="_" />
                                                    } />
                                            }
                                        />
                                    </div>
                                    <div className="form-group col-lg-3">
                                        <label className="form-label">Select Contractor <span className="color-pink">*</span></label><br />
                                        <Select
                                            {...register("formFields.contractor")}
                                            size="large" placeholder="Select Contractor"
                                            className="form-control-custom"
                                            showSearch
                                            optionFilterProp="children"
                                            allowClear={true}
                                            onChange={onContractorChange}
                                            filterOption={(input, option) =>
                                                option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 || false
                                            }
                                            value={getValues().formFields?.contractor}
                                        >
                                            {/*                    <Option value="jack">Jack</Option>*/}
                                            {/*<option value='' />*/}
                                            {contractorsListData?.length > 0 && contractorsListData?.map((contractor, idx) => <option index={idx} key={contractor.contractors_ID} value={contractor.contractors_ID}>{contractor.name}</option>)}
                                        </Select>
                                    </div>
                                    <div className="form-group col-lg-3">
                                        <label className="form-label">Select Job</label><br />
                                        <Select
                                            {...register("formFields.job")}
                                            size="large" placeholder="Select Job"
                                            className="form-control-custom"
                                            showSearch
                                            optionFilterProp="children"
                                            allowClear={true}
                                            onChange={onJobChange}
                                            filterOption={(input, option) =>
                                                option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 || false
                                            }
                                            value={getValues().formFields?.job}
                                        >
                                            {/*<option value='' />*/}
                                            {jobsListData?.length > 0 && jobsListData?.map((job, idx) => <option index={idx} key={job.jobs_ID} value={job.jobs_ID}>{job.jobName}</option>)}
                                        </Select>
                                    </div>
                                    <div className="form-group col-lg-3">
                                        <label className="form-label">Payment Status</label><br />
                                        <Select
                                            {...register("formFields.paymentStatus")}
                                            size="large"
                                            className="form-control-custom"
                                            showSearch
                                            allowClear={false}
                                            optionFilterProp="children"
                                            onChange={onPaymentStatusChange}
                                            filterOption={(input, option) =>
                                                option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 || false
                                            }
                                            value={getValues().formFields?.paymentStatus}
                                        >
                                            <option value="Outstanding">Outstanding</option>
                                            <option value="Paid">Paid</option>
                                        </Select>
                                    </div>
                                    <div className="form-group col-lg-3">
                                        <label className="form-label">Show Archived Requests</label>
                                        <div className="form-control-custom no-border">
                                            <div className="custom-control custom-checkbox custom-control-inline">
                                                <input {...register("formFields.archived")} type="checkbox" className="custom-control-input" />
                                                <label className="custom-control-label"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-lg-12">
                                        <button type="button" className="btn btn-submit margin-0-10 mt-2" onClick={handleSearch}>
                                            Search
                                        </button>&nbsp;&nbsp;&nbsp;
                                        <button type="button" className="btn btn-outline-primary margin-0-10 mt-2" onClick={handleReset}>
                                            Reset
                                        </button>
                                        <span style={getValues().formFields?.hideActionObjects === true ? { display: 'none' } : {}}><button className="btn btn-submit float-right ms-3 mt-2" onClick={showSummary}>Show Summary</button></span>
                                        {/*                                <button className="btn btn-submit float-right ms-3" onClick={denyArchiveSelectedHours}>Deny/Archive Selected Hours</button>*/}
                                        <span style={getValues().formFields?.hideActionObjects === true ? { display: 'none' } : {}}><button className="btn btn-submit float-right ms-3 mt-2" onClick={assignSelectedHoursToCheck}>Assign Selected Hours To Check</button></span>
                                    </div>
                                </div>
                            </Panel>
                        </Collapse>
                        <hr style={{ border: "1px solid" }} />

                        <div>
                            <Spin spinning={!state.dataLoaded}>
                                <div style={!state.dataLoaded ? {} : { display: 'none' }}>
                                    <Alert
                                        message="Loading Eligible Requests For Processing"
                                        description="Please stand by while we retrieve all eligible requests"
                                        type="info"
                                    />
                                </div>
                                {state.dataLoaded &&
                                    <Fragment>
                                        {state.pendingPayments.length === 0 ?
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                                                <span>
                                                    No Pending Payments Found Matching Your Seletion Criteria
                                                </span>
                                            } />
                                            :
                                            <>
                                                <Table
                                                    className="custom-ant-selection"
                                                    hideSelectionColumn={true}
                                                    columns={columns2}
                                                    expandable={{
                                                        expandedRowRender,
                                                        onExpand
                                                    }}
                                                    rowKey={record => record.paymentHeader.uniqueRowID}
                                                    dataSource={state.pendingPayments}
                                                    size="small"
                                                    bordered
                                                    style={{ whiteSpace: 'pre' }}
                                                    pagination={{
                                                        defaultPageSize: 10,
                                                        showSizeChanger: true,
                                                        pageSizeOptions: ['10', '25', '50', '100'],
                                                        showTotal: (total, range) => (
                                                            <span className="color-dark-blue" style={!isMobile ? { left: 0, position: "absolute", fontSize: "large", fontWeight: "bold" } : { fontWeight: 'bold' }}>
                                                                Showing {range[0]}-{range[1]} of {total}
                                                            </span>
                                                        ),
                                                        onChange: (page, pageSize) => {
                                                            updatePageState(page, pageSize);
                                                        },
                                                        current: currentPage,
                                                        pageSize: currentPageSize
                                                    }}
                                                />
                                            </>
                                        }
                                    </Fragment>
                                }
                            </Spin>
                        </div>
                    </Fragment>
                </div>
            </div>
            <Modal
                visible={modalInfo.showmodal}
                title="Payment Notes"
                onCancel={closeModal}
                footer={[
                    <button key="1" className="btn btn-submit" onClick={onProcessPaymentNotesChange}>
                        Save Notes
                    </button>,
                    <span key="2">&nbsp;&nbsp;</span>,
                    <button key="3" className="btn btn-outline-primary" onClick={closeModal}>
                        Close
                    </button>
                ]}
            >
                <div className="row">
                    <div className="col-lg-12">
                        <label className="form-label mb-0">Internal Note:</label>
                    </div>
                    <div className="form-group col-lg-12">
                        <textarea rows="4" {...register("modalFields.paymentNote")} className="form-control-custom" />
                    </div>

                    <div className="col-lg-12">
                        <label className="form-label mb-0">Note To Contractor:</label>
                    </div>
                    <div className="form-group col-lg-12">
                        <textarea rows="4" {...register("modalFields.noteToContractor")} className="form-control-custom" />
                    </div>

                    <div className="form-group col-lg-12">
                        <div className="rcorners p-2">
                            <input type="radio" name="notesExceptions" {...register("modalFields.notesExceptions")} value="Exceptions" className="mt-1" />&nbsp;<label className="form-label">EXCEPTIONS</label>&nbsp;&nbsp;&nbsp;
                            <input type="radio" name="notesExceptions" {...register("modalFields.notesExceptions")} value="NoExceptions" className="mt-1" />&nbsp;<label className="form-label">NO Exceptions</label>&nbsp;&nbsp;&nbsp;
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                visible={ppModalInfo.showmodal}
                title="Item Selection Summary"
                onCancel={closePPModal}
                footer={[
                    <button key="1" className="btn btn-submit" onClick={onAssignHoursToCheck} style={getValues().ppModalFields?.displayMode === 'assignHoursToCheck' ? {} : { display: 'none' }}>
                        Assign To Check
                    </button>,
                    //<button className="btn btn-submit" onClick={onArchivePayments} style={getValues().ppModalFields?.displayMode === 'archivePayments' ? {} : { display: 'none' }}>
                    //    Archive Items
                    //</button>,
                    <span key="2">&nbsp;&nbsp;</span>,
                    <button key="3" className="btn btn-outline-primary" onClick={closePPModal}>
                        Close
                    </button>
                ]}
            >
                <div className="row">
                    <div className="col-lg-4">
                        <label className="form-label mb-0">Items Selected:</label>
                    </div>
                    <div className="col-lg-8">
                        <label className="form-label mb-0 color-dark-blue">{getValues().ppModalFields?.itemsSelected}</label>
                    </div>

                    <div className="col-lg-4">
                        <label className="form-label mb-0">Total Hours:</label>
                    </div>
                    <div className="col-lg-8">
                        <label className="form-label mb-0 color-dark-blue">{getValues().ppModalFields?.totalHours}</label>
                    </div>

                    <div className="col-lg-4">
                        <label className="form-label mb-0">Total Amount:</label>
                    </div>
                    <div className="col-lg-8">
                        <label className="form-label mb-0 color-dark-blue">{getValues().ppModalFields?.totalAmount}</label>
                    </div>

                    <p></p>
                    <div className="col-lg-4">
                        <label className="form-label mb-0" style={getValues().ppModalFields?.displayMode === 'assignHoursToCheck' ? {} : { display: 'none' }}>Assign To Check #:</label>
                    </div>
                    <div className="col-lg-4">
                        <input type="text" {...register("ppModalFields.checkNo")} className="form-control-custom" style={getValues().ppModalFields?.displayMode === 'assignHoursToCheck' ? {} : { display: 'none' }} />
                    </div>
                    <div className="col-lg-4"></div>
                </div>
            </Modal>
        </div>
    );
}