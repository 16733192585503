import React from 'react';

class Globals extends React.Component {

    static userInfo = {
        userId: "",
        username: "",
        fullName: "",
        userType: "",
        contractors_ID: "",
        contacts_ID: "",
        mcacMember: "",
        isLoggedIn: false,
        bearer: "",
        mainContact: false
    };

    static isDirtyPage = false;

    static members = [];
    static loadingMembers = false;
    static searchCriteria = '';
}

export default Globals;
