import React, { useState, useEffect } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import Axios from '../../config/axios';
import { dateWithNoTimezone } from '../Helpers/DateFormat';
import { Fragment } from 'react';
import Moment from 'moment';
import { Empty, Spin, Alert, Table, Input, Badge, AutoComplete, Skeleton } from 'antd';

export default function MRRequestHistorySlider(props) {
    const [state, setState] = useState({
        showPane: false,
        dataLoaded: false,
        requestHistory: {}
    });

    const [selectedRowKeys, setSelectedRowKeys] = useState(['']);
    const [currentPage, setCurrentPage] = useState([1]);
    const [currentPageSize, setCurrentPageSize] = useState([10]);

    useEffect(() => {
        var alive = true;

        if (alive && props.selectedRequestId !== null) {
            Axios.get(`/api/GetRequestHistory`, {
                params: {
                    requestID: props.selectedRequestId
                }
            }).then(response => {
                let requestHistoryList = response.data;

                setState({
                    ...state,
                    requestHistory: requestHistoryList,
                    dataLoaded: true,
                    showPane: true
                });
            }).catch(error => {
                console.log(error);
            });
        }
        return () => {
            alive = false;
        };

    }, [props]);

    function hidePane() {
        setState({
            ...state,
            showPane: false,
        });
    }

    const columns = [
        {
            dataIndex: 'marketRecoveryHistory_ID',
            title: 'marketRecoveryHistory_ID',
            key: 'marketRecoveryHistory_ID',
            hidden: true
        }, {
            dataIndex: 'transDate',
            title: 'Date',
            key: 'transDate',
            width: 140,
            render: (transDate) => {
                return (
                    <div>
                        {transDate === null ? '' : Moment(dateWithNoTimezone(transDate)).format("L")}
                    </div>
                );
            },
            sorter: (a, b) => a.mrRequest?.requestDate?.localeCompare(b.mrRequest?.requestDate)
        }, {
            dataIndex: 'description',
            title: 'Description',
            key: 'description',
            sorter: (a, b) => a.description.localeCompare(b.description)
        }
    ].filter(item => !item.hidden);

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: 0, // Set the width to 0
        renderCell: () => "", // Render nothing inside
    };

    function updatePageState(page, pageSize) {
        setCurrentPage(page);
        setCurrentPageSize(pageSize);

        setState({
            ...state,
        });
    }

    return (
        <SlidingPane
            className='some-custom-class w-65'
            overlayClassName='showCard'
            isOpen={state.showPane}
            title='Market Recovery Request History'
            onRequestClose={hidePane}
            width={'65%'}
        >
            <div className="slide-pane-body scrollableDiv">
                <div>
                    {state.dataLoaded &&
                        <Fragment>
                            {state.requestHistory.length === 0 ?
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                                    <span>
                                        No Market Recovery Request History Found
                                    </span>
                                } />
                                :
                                <div>
                                    <Table className="custom-ant-selection"
                                        rowKey={(record) => record.marketRecoveryHistory_ID}
                                        rowSelection={rowSelection}
                                        hideSelectionColumn={true}
                                        bordered
                                        size="small"
                                        dataSource={state.requestHistory}
                                        style={{ whiteSpace: 'pre' }}
                                        columns={columns}
                                        pagination={{
                                            defaultPageSize: 10,
                                            showSizeChanger: true,
                                            pageSizeOptions: ['10', '25', '50', '100'],
                                            showTotal: (total, range) => (
                                                <span className="color-dark-blue" style={{ left: 0, position: "absolute", fontSize: "large", fontWeight: "bold" }}>
                                                    Showing {range[0]}-{range[1]} of {total}
                                                </span>
                                            ),
                                            onChange: (page, pageSize) => {
                                                updatePageState(page, pageSize);
                                            },
                                            current: currentPage,
                                            pageSize: currentPageSize
                                        }}
                                        onRow={(record, index) => {
                                            return {
                                                onClick: () => {
                                                    let selRows = [record.marketRecoveryHistory_ID];
                                                    setSelectedRowKeys([...selRows]);
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            }
                        </Fragment>
                    }
                    {/*{state.dataLoaded &&*/}
                    {/*    <Fragment>*/}
                    {/*        {state.requestHistory.length === 0 ?*/}
                    {/*            <div>No Market Recovery Request History Found</div>*/}
                    {/*            :*/}
                    {/*            <div className="fullTable">*/}
                    {/*                <ToolkitProvider*/}
                    {/*                    keyField='marketRecoveryHistory_ID'*/}
                    {/*                    data={state.requestHistory}*/}
                    {/*                    columns={columns}*/}
                    {/*                    search*/}
                    {/*                >*/}
                    {/*                    {*/}
                    {/*                        props => (*/}
                    {/*                            <div>*/}
                    {/*                                <BootstrapTable*/}
                    {/*                                    pagination={paginationFactory(options)}*/}
                    {/*                                    {...props.baseProps}*/}
                    {/*                                    rowEvents={rowEvents}*/}
                    {/*                                    selectRow={selectRow}*/}
                    {/*                                    hover condensed />*/}
                    {/*                            </div>*/}
                    {/*                        )*/}
                    {/*                    }*/}
                    {/*                </ToolkitProvider>*/}
                    {/*            </div>*/}
                    {/*        }*/}
                    {/*    </Fragment>*/}
                    {/*}*/}
                </div>
            </div>

            <div className="modal-footer">
                <div className="form-group col-12 padding-25-10">
                    <button className="btn btn-outline-primary margin-left-15 float-right" onClick={hidePane}>Close</button>
                </div>
            </div>

        </SlidingPane>
    );
}