import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import statesList from '../Helpers/StatesList';
import NumberFormat from 'react-number-format';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';

export default function ContractorTab(props) {
    const [state, setState] = useState({
        showPane: props.showPane,
        contractors: [],
        selectedContractorId: '',
        selectedIdx: '',
        formFields: {},
        btnSubmitClicked: false,
        buttonLabel: '',
        states: statesList
    });

    useEffect(() => {
        if (props.selectedContractorId !== null && props.selectedContractorId !== "") {
            Axios.get(`/api/GetContractorById`, {
                params: {
                    contractorID: props.selectedContractorId
                }
            }).then(response => {
                setState({
                    ...state,
                    formFields: response.data,
                    showPane: props.showPane,
                    contractors: props.contractors,
                    selectedContractorId: props.selectedContractorId,
                    selectedIdx: props.selectedIdx,
                    buttonLabel: 'Update Contractor Info'
                });
            }).catch(error => {
                console.log(error);
            });
        }
    }, [props]);

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setState({
            ...state,
            formFields: { ...state.formFields, [name]: value }
        });
    }

    function handleMCACMemberChange(e) {
        setState({
            ...state,
            formFields: { ...state.formFields, mcacMember: e.target.value }
        });
    }

    function handleStatusChange(e) {
        setState({
            ...state,
            formFields: { ...state.formFields, status: e.target.value }
        });
    }

    function handleSubmit() {
        let formFields = state.formFields;

        //this uses the ignore field in the AccountsModel to pass in the selected filterType to be used when returning the new list of Accounts
        formFields.typeFilter = props.selectedAccountType;

        if (!state.formFields?.name) {
            toast.error("Mandatory fields missing!");
            return;
        } else {
            Axios.post(`/api/UpdateContractor`, formFields
            ).then(response => {
                toast.success("Contractor Information Successfully Updated");
            }).catch(error => {
                toast.error("Problem Encountered Updating Contractor");

                console.log(error);
            });
        }
    }

    return (
        <div>
            <Card className="local777Card">
                <Card.Header><span>Contractor Information (*Indicates required field)</span></Card.Header>
                <Card.Body>
                    <form>
                        <div className="row">
                            <div className="form-group col-lg-12">
                                <label className="form-label">* Contractor Name</label>
                                <input type="text" name="name" value={state.formFields.name || ''} onChange={handleInputChange} className={!state.formFields.name && state.btnSubmitClicked ? 'form-control-custom required' : 'form-control-custom'} />
                            </div>

                            <div className="form-group col-lg-6">
                                <label className="form-label">Street 1</label>
                                <input type="text" name="street1" value={state.formFields.street1 || ''} onChange={handleInputChange} className="form-control-custom" />
                            </div>
                            <div className="form-group col-lg-6">
                                <label className="form-label">Street 2</label>
                                <input type="text" name="street2" value={state.formFields.street2 || ''} onChange={handleInputChange} className="form-control-custom" />
                            </div>

                            <div className="form-group col-lg-4">
                                <label className="form-label">City</label>
                                <input type="text" name="city" value={state.formFields.city || ''} onChange={handleInputChange} className="form-control-custom" />
                            </div>
                            <div className="form-group col-lg-4">
                                <label className="form-label">State</label>
                                <select className="form-control-custom" value={state.formFields.state} name="state" onChange={handleInputChange}>
                                    <option></option>
                                    {state.states.map((state, idx) => <option key={idx} value={state.abbreviation}>{state.name}</option>)}
                                </select>
                            </div>
                            <div className="form-group col-lg-4">
                                <label className="form-label">Zip</label>
                                <input type="text" name="zip" value={state.formFields.zip || ''} onChange={handleInputChange} className="form-control-custom" />
                            </div>

                            <div className="form-group col-lg-4">
                                <label className="form-label">Phone</label>
                                <NumberFormat className="form-control-custom" name="phone" value={state.formFields.phone || ''} onChange={handleInputChange} format="###-###-####" mask="_" />
                            </div>
                            <div className="form-group col-lg-4">
                                <label className="form-label">Fax</label>
                                <NumberFormat className="form-control-custom" name="fax" value={state.formFields.fax || ''} onChange={handleInputChange} format="###-###-####" mask="_" />
                            </div>
                            <div className="form-group col-lg-4">
                                <label className="form-label">Web Site</label>
                                <input type="text" name="website" value={state.formFields.website || ''} onChange={handleInputChange} className="form-control-custom" />
                            </div>

                            <div className="form-group col-lg-3">
                                <label className="form-label">MCAC Member:</label><br />
                                <input type="radio" onChange={handleMCACMemberChange} name="mcacMember" value="Y" className="mt-1" checked={state.formFields.mcacMember === "Y"} />&nbsp;<label className="form-label">Yes</label>&nbsp;&nbsp;&nbsp;
                                <input type="radio" onChange={handleMCACMemberChange} name="mcacMember" value="N" className="mt-1" checked={state.formFields.mcacMember === "N"} />&nbsp;<label className="form-label">No</label>&nbsp;&nbsp;&nbsp;
                            </div>
                            <div className="form-group col-lg-3">
                                <label className="form-label">Status:</label><br />
                                <input type="radio" onChange={handleStatusChange} name="status" value="A" className="mt-1" checked={state.formFields.status === "A"} />&nbsp;<label className="form-label">Active</label>&nbsp;&nbsp;&nbsp;
                                <input type="radio" onChange={handleStatusChange} name="status" value="I" className="mt-1" checked={state.formFields.status === "I"} />&nbsp;<label className="form-label">Inactive</label>&nbsp;&nbsp;&nbsp;
                            </div>
                            <div className="form-group col-lg-6">
                                <label className="form-label">Project Profiling Visibility</label><br />
                                <input type="checkbox" id="visibility" name="visibility" value={state.formFields.visibility || ''} onChange={handleInputChange} />
                                <label className="form-label" htmlFor="visibility" />&nbsp;Make Contractor Available In Project Profiling
                            </div>
                        </div>
                    </form>
                    <div className="modal-footer">
                        <div className="form-group col-12 padding-25-10">
                            <button className="btn btn-submit margin-left-15 float-left" onClick={handleSubmit}>Save Changes</button>&nbsp;&nbsp;&nbsp;
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}