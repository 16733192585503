import React, { useState, useEffect } from 'react';
import Axios from '../../config/axios';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { toast, confirm } from '@rickylandino/react-messages';
import { Fragment } from 'react';
import Card from 'react-bootstrap/Card';
import statesList from '../Helpers/StatesList';
import NumberFormat from 'react-number-format';
import { message } from 'antd';
import { useForm, Controller } from "react-hook-form";

export default function UserDetailsSlider(props) {
    const [state, setState] = useState({
        showPane: props.showPane,
        users: [],
        userLoaded: false,
        selectedUserId: '',
        selectedIdx: '',
        formFields: {},
        btnSubmitClicked: false,
        buttonLabel: '',
        states: statesList
    });

    const { register, getValues, setValue, control, watch } = useForm();

    useEffect(() => {
        setState({
            ...state,
            states: statesList
        });
    }, []);

    useEffect(() => {
        if (state.userLoaded === false) {
            if (props.selectedUserId !== null) {
                Axios.get(`/api/GetUserById`, {
                    params: {
                        userid: props.selectedUserId
                    }
                }).then(response => {
                    setValue('formFields', response.data);

                    setState({
                        ...state,
                        formFields: response.data,
                        showPane: props.showPane,
                        users: props.users,
                        selectedUserId: props.selectedUserId,
                        selectedIdx: props.selectedIdx,
                        buttonLabel: 'Update User Info',
                        userLoaded: true
                    });
                }).catch(error => {
                    console.log(error);
                });
            }
            else {
                let formFields = {
                    status: 'A',
                    firstName: '',
                    lastName: '',
                    email: '',
                    password: '',
                    userType: 'Select Type'
                }

                setState({
                    ...state,
                    showPane: props.showPane,
                    users: props.users,
                    selectedUserId: '',
                    selectedIdx: '',
                    formFields,
                    userLoaded: true
                });
            }
        }
    }, [props]);

    function hidePane() {
        setState({
            ...state,
            showPane: false
        });

        setTimeout(() => props.hidePane(), 1000);
    }

    function screenDataOK() {
        let message = '';

        let formFields = getValues('formFields');

        if (formFields.firstName === '') {
            message += '&bull;&nbsp;First Name</br>';
        }
        if (formFields.lastName === '') {
            message += '&bull;&nbsp;Last Name</br>';
        }
        if (formFields.email === '') {
            message += '&bull;&nbsp;Email</br>';
        }
        if (formFields.password === '') {
            message += '&bull;&nbsp;Password</br>';
        }
        if (formFields.userType === 'Select Type') {
            message += '&bull;&nbsp;User Type</br>';
        }

        if (message != '') {
            message = "<strong>Mandatory fields missing or invalid data specified</strong><br />" + message;
            toast.error(<span style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: message }}></span>, { alignment: 'top-center', duration: 5 })

            return false;
        }
        else {
            return true;
        }
    }

    function handleSubmit() {
        let formFields = getValues('formFields');

        if (screenDataOK() === false) {
            return;
        }

        if (state.selectedUserId) {
            Axios.post(`/api/UpdateUser`, formFields
            ).then(response => {
                toast.success("User Information Successfully Updated");

                //now update the parent list 
                let users = props.users;
                const idxToDirectTo = users.findIndex(a => a.users_ID === props.selectedUserId);
                users[idxToDirectTo] = formFields;

                props.updateTableDisplay(users, idxToDirectTo);

                hidePane();
            }).catch(error => {
                toast.error("Problem Encountered Updating User");

                console.log(error);
            });
        }
        else {
            formFields.users_ID = 0;

            Axios.post(`/api/AddNewUser`, formFields
            ).then(response => {
                //now update the parent list
                if (response.data) {
                    toast.success("New User Successfully Added");

                    //now update the parent list
                    let users = response.data.users;
                    const idxToDirectTo = users.findIndex(a => a.users_ID === response.data.newID);

                    props.updateTableDisplay(users, idxToDirectTo);

                    //hidePane();

                    setState({
                        ...state,
                        showPane: false
                    });
                }
            }).catch(error => {
                toast.error("Problem Encountered Adding New User");

                console.log(error);
            });
        }
    }

    function handleSendPasswordResetEmail() {
        let formFields = getValues('formFields');

        if (formFields.email === '' || formFields.email === undefined) {
            toast.error("A Contact Name and Email must be provided before sending a password reset email.");
            return;
        }

        const key = "sendpasswordresetemail";
        message.loading({
            content: 'Sending Password Reset Email...',
            key,
            duration: 0
        });

        let postdata = {
            userid: formFields.email
        };

        Axios.post(`/api/SendPasswordResetEmail`, postdata
        ).then(response => {
            message.destroy(key);

            setState({
                ...state,
                loading: false
            });

            if (response.data === 'OK') {
                toast.success("An email has been sent to " + formFields.email + " with instructions and a link to reset their password");
            }
            else {

            }

        }).catch(error => {
            setState({
                ...state,
                loading: false
            });

            let message = "Something went wrong, please try again later or call Andrea Berkmoes at (203) 317-4750 or <a href=\"mailto: aberkmoes@local777.com\">aberkmoes@local777.com</a>.";
            toast.error(<span style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: message }}></span>, { alignment: 'top-center', duration: 5 })
        });

    }

    return (
        <SlidingPane
            className='some-custom-class w-75'
            overlayClassName='showCard'
            isOpen={state.showPane}
            title={state.selectedUserId ? 'Edit Existing User' : 'Add New User'}
            onRequestClose={hidePane}
        >
            <div className="slide-pane-body scrollableDiv">
                <div>
                    {!state.loading &&
                        <Fragment>
                            <Card className="local777Card">
                                <Card.Header><span>User Information (*Indicates required field)</span></Card.Header>
                                <Card.Body>
                                    <form>
                                        <div className="row">
                                            <div className="form-group col-lg-4">
                                                <label className="form-label">* First Name</label>
                                                <input type="text" name="firstName" {...register('formFields.firstName')} className="form-control-custom" />
                                            </div>
                                            <div className="form-group col-lg-4">
                                                <label className="form-label">* Last Name</label>
                                                <input type="text" name="lastName" {...register('formFields.lastName')} className="form-control-custom" />
                                            </div>
                                            <div className="form-group col-lg-4">
                                                <label className="form-label">Title</label>
                                                <input type="text" name="title" {...register('formFields.title')} className="form-control-custom" />
                                            </div>

                                            <div className="form-group col-lg-4">
                                                <label className="form-label">* Email</label>
                                                <input type="text" name="email"{...register('formFields.email')} className="form-control-custom" />
                                            </div>
                                            <div className="form-group col-lg-8"></div>

                                            <div className="form-group col-lg-2">
                                                <label className="form-label">* User Type</label>
                                                <select name="userType" className="form-control-custom"  {...register('formFields.userType')}>
                                                    <option>Select Type</option>
                                                    <option>Administrator</option>
                                                    <option>Agent</option>
                                                    <option>SuperAgent</option>
                                                </select>
                                            </div>

                                            {/*<div className="form-group col-lg-2">*/}
                                            {/*    <label className="form-label">* User Type</label>*/}
                                            {/*    <select name="userType" className="form-control-custom" value={state.formFields.userType} onChange={handleInputChange}>*/}
                                            {/*        <option>Select Type</option>*/}
                                            {/*        <option>Administrator</option>*/}
                                            {/*        <option>Agent</option>*/}
                                            {/*        <option>SuperAgent</option>*/}
                                            {/*    </select>*/}
                                            {/*</div>*/}
                                        </div>
                                    </form>
                                </Card.Body>
                            </Card>
                        </Fragment>
                    }
                </div>
            </div>

            <div className="modal-footer">
                <div className="form-group col-12 padding-25-10">
                    <button className="btn btn-submit margin-left-15 float-left" onClick={handleSubmit}>Save Changes</button>&nbsp;&nbsp;&nbsp;
                    <button className="btn btn-submit margin-left-15 float-left" onClick={handleSendPasswordResetEmail}>Send Password Reset Email</button>&nbsp;&nbsp;&nbsp;
                    <button className="btn btn-outline-primary margin-left-15 float-right" onClick={hidePane}>Close</button>
                </div>
            </div>
        </SlidingPane>
    );
}