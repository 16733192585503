import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';
import { Fragment } from 'react';
import { Empty, Spin, Table } from 'antd';
import CVContactDetailsSlider from './CVContactDetailsSlider';

export default function CVContactsTab(props) {
    const [state, setState] = useState({
        selectedContractorId: '',
        contacts: [],
        dataLoaded: false,
        showContactDetailsSlider: false,
        contactEditMode: 'Add',
        selectedContactIdx: null,
        selectedContact: {}
    });

    const [selectedRowKeys, setSelectedRowKeys] = useState(['']);
    const [currentPage, setCurrentPage] = useState([1]);
    const [currentPageSize, setCurrentPageSize] = useState([10]);

    useEffect(() => {
        var alive = true;

        if (alive && props.selectedContractorId !== null) {
            Axios.get(`/api/GetContractorContacts`, {
                params: {
                    contractorID: props.selectedContractorId
                }
            }).then(response => {
                let contactsList = response.data;

                console.log(contactsList);

                setState({
                    ...state,
                    contacts: contactsList,
                    dataLoaded: true,
                    selectedContractorId: props.selectedContractorId
                });
            }).catch(error => {
                console.log(error);
            });
        }
        return () => {
            alive = false;
        };

    }, [props]);

    const columns = [
        {
            dataIndex: 'contacts_ID',
            title: 'contacts_ID',
            key: 'contacts_ID',
            hidden: true
        }, {
            dataIndex: 'contactName',
            title: 'Contact Name',
            key: 'contactName',
            sorter: (a, b) => a.contactName.localeCompare(b.contactName)
        }, {
            dataIndex: 'contactTitle',
            title: 'Title',
            key: 'contactTitle',
            sorter: (a, b) => a.contactTitle.localeCompare(b.contactTitle)
        }, {
            dataIndex: 'contactEmail',
            title: 'Email',
            key: 'contactEmail',
            sorter: (a, b) => a.contactEmail.localeCompare(b.contactEmail)
        }, {
            dataIndex: 'contactPhone',
            title: 'Phone',
            key: 'contactPhone',
            sorter: (a, b) => a.contactPhone.localeCompare(b.contactPhone)
        }, {
            dataIndex: 'mainContact',
            title: 'Main Contact',
            key: 'mainContact',
            align: 'center',
            width: 120,
            render: (cell, row, idx) => row.mainContact === true ? <div><i id="mainContact" className="fas fa-check fa-1x ml-3 text-center hover"></i></div> : <div></div>,
        //}, {
        //    title: 'Contact Details',
        //    key: 'action',
        //    width: 120,
        //    render: (record) => (
        //        <span className="hover" onClick={(e) => LoadContactDetails(e, record)}><i id="contactDetails" className="fas fa-edit fa-1x ml-3 text-center hover"></i></span>
        //    ),
        //    align: 'center'
        //}, {
        //    title: 'Delete Contact',
        //    key: 'action2',
        //    width: 120,
        //    render: (record) => (
        //        <i id="deleteContact" className="far fa-trash-alt fa-1x ml-3 text-center hover text-danger" onClick={(e) => handleDeleteContact(e, record)}></i>
        //    ),
        //    align: 'center'
        }
    ].filter(item => !item.hidden);

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: 0, // Set the width to 0
        renderCell: () => "", // Render nothing inside
    };

    function LoadContactDetails(contactID, rowIndex) {
        Axios.get(`/api/GetContactById`, {
            params: {
                contactID: contactID
            }
        }).then(response => {
            setState({
                ...state,
                selectedContactIdx: rowIndex,
                selectedContact: response.data,
                selectedContactId: contactID,
                showContactDetailsSlider: true
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function handleAddContact() {
        setState({
            ...state,
            showContactDetailsSlider: true,
            buttonLabel: 'Save New Contact',
            modalHeader: 'Add New Contact',
            contactEditMode: 'Add',
            formFields: {},
            selectedContactId: null,
            selectedContactName: ''
        });
    }

    function handleDelete(contact) {
        confirm({
            title: "You are about to permanently delete this Contact",
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"]
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {
                Axios.post(`/api/DeleteContact`, contact
                ).then(response => {
                    if (response.data) {
                        toast.success("Contact has been deleted");

                        setState({
                            ...state,
                            contacts: response.data,
                            showContactDetailsSlider: false
                        });
                    }
                }).catch(error => {
                    console.log(error);
                });

                return 0;
            } else {
                return 0;
            }
        });
    }

    async function updateTableDisplay(contacts, idxToDirectTo = null) {
        await setState({ ...state, dataLoaded: false });

        setState({
            ...state,
            contacts,
            selectedContact: contacts[idxToDirectTo],
            dataLoaded: true,
            showContactDetailsSlider: false
        });
    }

    function updatePageState(page, pageSize) {
        setCurrentPage(page);
        setCurrentPageSize(pageSize);

        setState({
            ...state,
        });
    }

    return (
        <div>
            <Card className="local777Card">
                <Card.Header><span>Contractor Contacts</span></Card.Header>
                <Card.Body>
                    <div className="panel-hdr noTitle">
                        <button type="button" className="btn btn-submit margin-0-10" onClick={handleAddContact}>
                            Request New Contact
                        </button>
                    </div>
                    <div>
                        {state.dataLoaded &&
                            <Fragment>
                                {state.contacts.length === 0 ?
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                                        <span>
                                            No Contacts Found For This Contractor
                                        </span>
                                    } />
                                    :
                                    <Table className="custom-ant-selection"
                                        rowKey={item => item.contacts_ID}
                                        rowSelection={rowSelection}
                                        hideSelectionColumn={true}
                                        bordered
                                        size="small"
                                        dataSource={state.contacts}
                                        style={{ whiteSpace: 'pre' }}
                                        columns={columns}
                                        pagination={{
                                            defaultPageSize: 10,
                                            showSizeChanger: true,
                                            pageSizeOptions: ['10', '25', '50', '100'],
                                            showTotal: (total, range) => (
                                                <span className="color-dark-blue" style={{ left: 0, position: "absolute", fontSize: "large", fontWeight: "bold" }}>
                                                    Showing {range[0]}-{range[1]} of {total}
                                                </span>
                                            ),
                                            onChange: (page, pageSize) => {
                                                updatePageState(page, pageSize);
                                            },
                                            current: currentPage,
                                            pageSize: currentPageSize
                                        }}
                                        onRow={(record) => {
                                            return {
                                                onClick: () => {
                                                    let selRows = [record.contacts_ID];
                                                    setSelectedRowKeys([...selRows]);
                                                }
                                            }
                                        }}
                                    />
                                }
                            </Fragment>
                        }
                    </div>
                    {state.showContactDetailsSlider &&
                        <CVContactDetailsSlider showPane={state.showContactDetailsSlider} selectedContactId={state.selectedContactId} contacts={state.contacts} selectedContractorId={state.selectedContractorId}
                            selectedIdx={state.selectedContactIdx} updateTableDisplay={updateTableDisplay} hidePane={() => setState({ ...state, showContactDetailsSlider: false })} contractorName={props.contractorName} />
                    }
                </Card.Body>
            </Card>
        </div>
    );
}