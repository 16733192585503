import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { Select, Spin, Alert, Empty, Table, Collapse } from 'antd';
import { Fragment } from 'react';
import Axios from '../../config/axios';
import JobDetailsSlider from './JobDetailsSlider';
import { useMediaQuery } from 'react-responsive';
import { SM_SCREEN, XL_SCREEN } from '../../services/SpaService';

export default function JobManager(props) {
    const { Panel } = Collapse;

    const [state, setState] = useState({
        jobs: [],
        dataLoaded: false,
        showJobDetailsSlider: false,
        jobEditMode: 'Add',
        selectedJobIdx: null,
        selectedJob: {},
        formFields: {},
        selectedJobId: null
    });

    const isDesktop = useMediaQuery({ minWidth: XL_SCREEN });
    const isMobile = useMediaQuery({ maxWidth: SM_SCREEN });

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const [jobsListData, setJobsListData] = useState([]);

    const [selectedRowKeys, setSelectedRowKeys] = useState(['']);
    const [currentPage, setCurrentPage] = useState([]);
    const [currentPageSize, setCurrentPageSize] = useState([]);

    const { register, getValues, setValue, control, watch } = useForm();

    useEffect(() => {
        setCurrentPage(1);
        setCurrentPageSize(10);

        setValue('formFields', {});

        setValue('formFields.jobStatus', 'Active');
        setValue('formFields.zoneRegion', 'All Zones')
        setValue('formFields.job', null)

        setState({
            ...state
        });

        //console.log('about to initialize...');
        InitializeJobManager();
    }, []);

    function InitializeJobManager() {
        Axios.get(`/api/InitializeJobManager`, {
            params: {
                jobStatus: 'Active',
                zoneRegion: "All Zones",
                jobs_ID: null
            }
        }).then(response => {
            setValue("matchingJobs", response.data.matchingJobs);
            
            let jobsList = response.data.jobs;
            setJobsListData(jobsList);

            setState({
                ...state,
                dataLoaded: true,
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function GetFilteredJobs() {
        Axios.get(`/api/GetFilteredJobs`, {
            params: {
                jobStatus: getValues().formFields.jobStatus,
                zoneRegion: getValues().formFields.zoneRegion,
                jobs_ID: getValues().formFields.job
            }
        }).then(response => {
            setValue("matchingJobs", response.data);

            setState({
                ...state,
                dataLoaded: true,
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function archivedFormatter(row) {
        if (row.archived === 1) {
            return (
                <div><i id="arch" className="fa fa-check ml-3 hover" /></div>
            );
        }
        else {
            return (
                <div></div>
            );
        }
    }

    const columns = [
        {
            title: 'jobs_ID', dataIndex: 'jobs_ID', key: 'jobs_ID', hidden: true
        }, {
            dataIndex: 'jobName',
            title: 'Job Name',
            key: 'jobName',
            sorter: (a, b) => a.jobName.localeCompare(b.jobName)
        }, {
            dataIndex: 'address',
            title: 'Address',
            key: 'address',
            sorter: (a, b) => a.address.localeCompare(b.address),
            responsive: ['xxl']
        }, {
            dataIndex: 'city',
            title: 'City',
            key: 'city',
            width: 220,
            sorter: (a, b) => a.city.localeCompare(b.city),
            responsive: ['xxl']
        }, {
            dataIndex: 'state',
            title: 'State',
            key: 'state',
            width: 60,
            sorter: (a, b) => a.state.localeCompare(b.state),
            responsive: ['sm']
        }, {
            dataIndex: 'zip',
            title: 'Zip Code',
            key: 'zip',
            width: 130,
            sorter: (a, b) => a.zip.localeCompare(b.zip),
            responsive: ['xxl']
        }, {
            dataIndex: 'region',
            title: 'Region',
            key: 'region',
            width: 100,
            sorter: (a, b) => a.region.localeCompare(b.region),
            responsive: ['xxl']
        }, {
            title: 'Archived',
            key: 'archived',
            width: 50,
            render: (row) => archivedFormatter(row),
            align: 'center',
            responsive: ['xxl']
        }, {
            title: 'Job Details',
            key: 'jobDetails',
            width: 120,
            render: (record) => (
                <span className="hover" onClick={(e) => LoadJobDetails(e, record)}><i id="jobDetails" className="fas fa-edit fa-1x ml-3 text-center hover"></i></span>
            ),
            align: 'center'
        }
    ].filter(item => !item.hidden);

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: 0, // Set the width to 0
        renderCell: () => "", // Render nothing inside
    };

    function LoadJobDetails(e, record) {
        setState({
            ...state,
            selectedJobId: record.jobs_ID,
            showJobDetailsSlider: true
        });
    }

    function handleAddNewJob() {
        setState({
            ...state,
            selectedJobIdx: null,
            selectedJobId: null,
            showJobDetailsSlider: true
        });
    }

    function handleReset() {
        setValue('formFields.jobStatus', 'Active');
        setValue('formFields.zoneRegion', 'All Zones')
        setValue('formFields.job', null)

        setState({
            ...state
        });

        GetFilteredJobs();
    }

    function handleSearch() {
        GetFilteredJobs();
    }

    function onJobChange(value) {
        setValue('formFields.job', value)

        setState({
            ...state,
            showJobDetailsSlider: false
        });
    }

    async function updateTableDisplay(jobs, idxToDirectTo = null) {
        var myPageSize = 10;
        var myPageIndex = 1;

        await setState({ ...state, dataLoaded: false });

        for (let i = 0; i < jobs.length; i++) {
            if (jobs[i].jobs_ID === jobs[idxToDirectTo].jobs_ID) {
                myPageIndex = Math.floor(i / myPageSize) + 1
                break;
            }
        }

        setValue("matchingJobs", jobs);

        //now set the selected Job
        let selRows = [jobs[idxToDirectTo].jobs_ID];
        setSelectedRowKeys([...selRows]);

        updatePageState(myPageIndex, myPageSize);

        setState({
            ...state,
            dataLoaded: true
        });
    }

    function updatePageState(page, pageSize) {
        setCurrentPage(page);
        setCurrentPageSize(pageSize);
    }

    return (
        <div>
            <div className="row">
                <div className="col-lg-12" >
                    <div className="panel-hdr">
                        <h2>
                            JOB MANAGER
                        </h2>
                        <button type="button" className="btn btn-submit margin-0-10" onClick={handleAddNewJob}>
                            Add New Job
                        </button>
                    </div>
                    <Fragment>
                        <br />
                        <Collapse defaultActiveKey={!isMobile ? ['1'] : []}>
                            <Panel header="Search Filters" key="1">
                        <div className="row">
                            <div className="form-group col-lg-2">
                                <label className="form-label">Job Status</label><br />
                                <select className="form-control-custom" {...register("formFields.jobStatus")}>
                                    <option>Active</option>
                                    <option>Archived</option>
                                    <option>All</option>
                                </select>
                            </div>
                            <div className="form-group col-lg-2">
                                <label className="form-label">Zone/Region</label>
                                <select className="form-control-custom" {...register("formFields.zoneRegion")}>
                                    <option>All Zones</option>
                                    <option>Z1</option>
                                    <option>Z2</option>
                                    <option>Z3</option>
                                    <option>Z4</option>
                                </select>
                            </div>
                            <div className="form-group col-lg-4">
                                <label className="form-label">Select Job</label><br />
                                <Select
                                    {...register("formFields.job")}
                                    size="large" placeholder="Select Job"
                                    className="form-control-custom"
                                    showSearch
                                    allowClear={true}
                                    optionFilterProp="children"
                                    //onSearch={onJobSearch}
                                    onChange={onJobChange}
                                    filterOption={(input, option) =>
                                        option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 || false
                                    }
                                    value={getValues().formFields?.job}
                                >
                                    {/*<option value='' />*/}
                                    {jobsListData?.length > 0 && jobsListData?.map((job, idx) => <option index={idx} key={job.jobs_ID} value={job.jobs_ID}>{job.jobName}</option>)}
                                </Select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group mt-3">
                                <button type="button" className="btn btn-submit margin-0-10" onClick={handleSearch}>
                                    Search
                                </button>&nbsp;&nbsp;&nbsp;
                                <button type="button" className="btn btn-outline-primary margin-0-10" onClick={handleReset}>
                                    Reset
                                </button>
                            </div>
                                </div>
                            </Panel>
                            </Collapse>
                        <hr style={{ border: "1px solid" }} />
                        <div>
                            <Spin spinning={!state.dataLoaded}>
                                <div style={!state.dataLoaded ? {} : { display: 'none' }}>
                                    <Alert
                                        message="Loading Market Recovery Jobs"
                                        description="Please stand by while we retrieve jobs matching your selection criteria"
                                        type="info"
                                    />
                                </div>
                                {state.dataLoaded &&
                                    <Fragment>
                                        {getValues().matchingJobs?.length === 0 ?
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                                                <span>
                                                    No Matching Jobs Found
                                                </span>
                                            } />
                                            :
                                            <div>
                                                <Table className="custom-ant-selection"
                                                    rowKey={(record) => record.jobs_ID}
                                                    rowSelection={rowSelection}
                                                    hideSelectionColumn={true}
                                                    bordered
                                                    size="small"
                                                    dataSource={getValues().matchingJobs}
                                                    style={{ whiteSpace: 'pre' }}
                                                    columns={columns}
                                                    pagination={{
                                                        defaultPageSize: 10,
                                                        showSizeChanger: true,
                                                        pageSizeOptions: ['10', '25', '50', '100'],
                                                        showTotal: (total, range) => (
                                                            <span className="color-dark-blue" style={!isMobile ? { left: 0, position: "absolute", fontSize: "large", fontWeight: "bold" } : { fontWeight: 'bold' }}>
                                                                Showing {range[0]}-{range[1]} of {total}
                                                            </span>
                                                        ),
                                                        onChange: (page, pageSize) => {
                                                            updatePageState(page, pageSize);
                                                        },
                                                        current: currentPage,
                                                        pageSize: currentPageSize
                                                    }}
                                                    expandable={!isDesktop && {
                                                        expandedRowRender: (record) => (
                                                            <ExpandedRow record={record} />
                                                        ),
                                                        rowExpandable: (record) => record.name !== 'Not Expandable',
                                                    }}
                                                    onRow={(record, index) => {
                                                        return {
                                                            onClick: () => {
                                                                let selRows = [record.jobs_ID];
                                                                setSelectedRowKeys([...selRows]);
                                                            },
                                                            onDoubleClick: (e) => {
                                                                LoadJobDetails(e, record);
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>
                                        }
                                    </Fragment>
                                }
                            </Spin>
                        </div>
                        {state.showJobDetailsSlider &&
                            <JobDetailsSlider showPane={state.showJobDetailsSlider} selectedJobId={state.selectedJobId} jobs={getValues().matchingJobs}
                                selectedIdx={state.selectedIdx} updateTableDisplay={updateTableDisplay} hidePane={() => setState({ ...state, showJobDetailsSlider: false })} />
                        }
                    </Fragment>
                </div>
            </div>
        </div>
    );
}

const ExpandedRow = ({ record }) =>
(
    <div className="row">
        <div className="form-group col-lg-4 col-md-6 col-12">
            <label className="form-label">Address: </label>
            <span>{record.address}</span>
        </div>
        <div className="form-group col-lg-4 col-md-6 col-12">
            <label className="form-label">City: </label>
            <span>{record.city}</span>
        </div>
        <div className="form-group col-lg-4 col-md-6 col-12">
            <label className="form-label">State: </label>
            <span>{record.state}</span>
        </div>
        <div className="form-group col-lg-4 col-md-6 col-12">
            <label className="form-label">Zip: </label>
            <span>{record.zip}</span>
        </div>
        <div className="form-group col-lg-4 col-md-6 col-12">
            <label className="form-label">Region: </label>
            <span>{record.region}</span>
        </div>
        <div className="form-group col-lg-4 col-md-6 col-12">
            <label className="form-label">Archived: </label>
            <span>{record.archived}</span>
        </div>
    </div>
);