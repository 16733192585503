import React, { useEffect, useState } from 'react';

import logo from '../../img/logo.png';
/*import logo from '../../img/local21_logo_sm.png';*/

import { toast } from '@rickylandino/react-messages';
import cookie from 'react-cookies';
import { Spin, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './login.css';
import Globals from '../../config/globals';
import Axios from '../../config/axios';
import { useNavigate } from "react-router-dom";
import ReactLoading from 'react-loading';
import ForgotPassword from './ForgotPassword';

export default function Login(props) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [state, setState] = useState({
        formFields: {
            userid: '',
            password: ''
        },
        //formFields: {
        //    userid: 'aberkmoes@local777.com',
        //    password: '1aberkmoes1!'
        //    //userid: 'doug.beck@bgmechanical.ng.com',
        //    //password: 'BGM1'
        //},
        loading: false,
        mode: 'login'
    });

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    //const [state, setState] = useState({
    //    formFields: {
    //        userid: 'aberkmoes@local777.com',
    //        password: '1aberkmoes1!'
    //    },
    //    loading: false
    //});

    //async function handleSubmit(e) {
    //    e.preventDefault();

    //    navigate("/dashboard", { replace: true });
    //}

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setState({
            ...state,
            formFields: { ...state.formFields, [name]: value }
        });
    }

    async function handleSubmit(e) {
        e.preventDefault();

        let myuserid = state.formFields.userid;
        let mypwd = state.formFields.password;

        if (myuserid === null || myuserid === "") {
            document.getElementById("userid").focus();
            toast.error("Login Denied - Username Required");
            return;
        }
        if (mypwd === null || mypwd === "") {
            document.getElementById("password").focus();
            toast.error("Login Denied - Password Required");
            return;
        }

        setState({
            ...state,
            loading: true
        });

        // ***** Validate user against db table *****
        let commandresult = "";

        let postdata = {};
        postdata.userid = state.formFields.userid;
        postdata.password = state.formFields.password;

        await Axios.post(`/api/validateuser`, postdata
        ).then(response => {
            commandresult = response.data;
        }).catch(error => {
            console.log(error);
        });

        // ***** If no limited scope bearer token returned, the user credentials were invalid *****
        if (commandresult === "") {
            setState({
                ...state,
                validUserFound: false,
                loading: false
            });

            toast.error("Login Denied - Invalid User Credentials");

            //smallBox({
            //    title: "Notice",
            //    content: "<i class='fa fa-clock-o'></i> <i>Invalid User Credentials.</i>",
            //    color: "#C46A69",
            //    iconSmall: "fa fa-times fa-2x fadeInRight animated",
            //    timeout: 4000
            //});

            return;
        } else {

            // set limited scope bearer token where Axios knows where to find it
            let userInfo = Globals.userInfo;
            userInfo.bearer = commandresult.token;
            userInfo.fullName = commandresult.fullName;
            userInfo.userId = commandresult.userID;
            userInfo.userType = commandresult.userType;
            userInfo.contractors_ID = commandresult.contractors_ID;
            userInfo.contacts_ID = commandresult.contacts_ID;
            userInfo.mcacMember = commandresult.mcacMember;
            userInfo.isLoggedIn = true;
            userInfo.mainContact = commandresult.mainContact;
            Globals.userInfo = userInfo;

            console.log(userInfo);

            window.sessionStorage.setItem("userInfo", JSON.stringify(userInfo));

            if (userInfo.userType === 'Contractor') {
                navigate("/dashboard", { replace: true, state: { selectedContractorId: userInfo.contractors_ID } });
            }
            else {
                navigate("/manage-contractor-requests", { replace: true });
            }
        }
    }

    function forgotPasswordClick() {
        setState({
            ...state,
            mode: 'forgotPassword'
        });
    }

    function goHome() {
        setState({
            ...state,
            mode: 'login'
        });
    }

    function handleConvertPasswords() {
        let postdata = {};

        Axios.post(`/api/EncryptPasswords`, postdata
        ).then(response => {
            if (response.data === true) {
                toast.success("All user and contact passwords have been reset.");
            }
            else {
                toast.error("Something went wrong");
            }
        }).catch(error => {
            toast.error("Something went wrong");
        });
    }


    return (
        <div className="background">
            <div className="blankpage-form-field">
                <div className="page-logo m-0 w-100 align-items-center justify-content-center rounded border-bottom-left-radius-0 border-bottom-right-radius-0 px-4">
                    <img src={logo} height="90px" alt="Local 777 Market Recovery" aria-roledescription="logo" />
                    {/*<img src={logo} alt="Local 21 Market Recovery" aria-roledescription="logo" />*/}
                </div>
                {state.mode === "login" &&
                    <div className="card p-4 border-top-left-radius-0 border-top-right-radius-0">
                        <form>
                            <div className="form-group">
                                <label className="form-label" htmlFor="username">Username</label>
                                <input type="text" id="userid" className="form-control-custom" name="userid" value={state.formFields.userid} onChange={handleInputChange} />
                                <span className="help-block">
                                    Your username
                                </span>
                            </div>
                            <div className="form-group">
                                <label className="form-label" htmlFor="password">Password</label>
                                <input type="password" id="password" name="password" value={state.formFields.password} onChange={handleInputChange} className="form-control-custom" />
                                <span className="help-block">
                                    Your password
                                </span>
                            </div>

                            {state.loading && <Spin indicator={antIcon} />}
                            <button className="btn btn-submit float-right" onClick={handleSubmit}>Secure login</button>
                        </form>

                        <div className="text-center row">
                            <div className="local777TextA col-6 align-items-right a" onClick={forgotPasswordClick}><strong>Forgot Password?</strong></div>
                            {/*<div className="local777TextA col-6 align-items-right a" onClick={forgotPasswordClick}><strong>Forgot Password</strong><span className="local777TextA col-6 align-items-right a" onClick={handleConvertPasswords}>?</span></div>*/}
                            &nbsp;&nbsp;&nbsp;
                        </div>
                    </div>
                }
                {state.mode === 'forgotPassword' &&
                    <div className="card p-4 border-top-left-radius-0 border-top-right-radius-0">
                        <ForgotPassword goHome={goHome} />
                        <div className="local777TextA col-4 align-items-right a" onClick={goHome}><strong>Go Back To Login</strong></div>
                    </div>
                }
            </div>

            {/*<Modal title="Basic Modal" visible={this.state.showTfaSetup} onCancel={() => this.setState({ showTfaSetup: false })}>*/}
            {/*    <TFASetup user={this.state.user} verify2FA={this.verify2FA} />*/}
            {/*</Modal>*/}
        </div>
    );
}